import { IonButton, IonButtons, IonCard, IonCol, IonContent, IonGrid, IonItem, IonLoading, IonRow, IonSpinner, IonToolbar } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useConfig } from "../data/store/useConfig";
import { useSongs } from "../data/store/useSongs";
import CompactSongList from "./CompactSongList";
import Content from "./Content";
const ArchiveDetail: React.FC = () => {
    const { id } = useParams() as any;
    const { push } = useHistory()
    const { archive, status, fetchSongsForDate } = useSongs();
    const groups = useConfig(x => x.activeMemberships());
    const isAlumni = useConfig(x => x.isAlumni());
    const [fetched, setFetched] = useState<string>()
    useEffect(() => {
        console.log(status, id);
        if (status === "fetching") {
            return;
        }
        const fetchNext = id + groups.join();
        if (typeof id !== "undefined" && id.length > 0 && groups.length > 0 && fetched !== fetchNext) {
            setFetched(fetchNext);
            fetchSongsForDate(groups, id);
        }
    }, [id, groups, fetchSongsForDate, status, fetched, isAlumni])


    const gotoDate = (dif: number) => () => {
        const next_date = new Date(id)
        next_date.setDate(next_date.getDate() + dif);
        const next_date_string = next_date.toISOString().split("T")[0];
        console.log(next_date_string)
        push("/archive/" + next_date_string);
    }

    const gotoYesterday = gotoDate(-1);
    const gotoTommorow = gotoDate(1);
    // const [selectedGroupId, setSelectedGroupId] = useState<string | undefined>(undefined)

    return <IonContent>

        <Content className='no-scroll'>
            <IonLoading isOpen={status === "initial"} />
            <IonGrid>
                <IonRow>
                    <IonCol sizeMd="8" sizeLg="6" offsetMd="2" offsetLg="3">
                        <IonCard>
                            <IonButton color="tertiary" expand="full" routerLink="/archives">
                                Archive
                            </IonButton>
                            {status === "fetching" && <IonItem><IonSpinner name="dots" /></IonItem>}
                            {status === "idle" && archive && archive.length === 0 && <IonItem>
                                No songs found in your groups on this day ~
                            </IonItem>}
                            {/* <IonSelect interface="action-sheet" placeholder="Select Groups">
                                <IonSelectOption>
                                    All My Groups
                                </IonSelectOption>
                                {activeGroups && activeGroups.map(group => {
                                    return <IonSelectOption value={group.id}>{group.title}</IonSelectOption>
                                })}
                            </IonSelect> */}
                        </IonCard>
                    </IonCol>
                </IonRow>
                <IonRow>
                    {<IonCol sizeMd="8" sizeLg="6" offsetMd="2" offsetLg="3">
                        {archive ? archive.length !== 0 ? < CompactSongList
                            nextPageText="Back to Archives" status={status} fetchMore={() => {
                                push("/archives/");
                            }} songRefs={archive} /> : <></> : <IonSpinner />}
                        <IonCard>
                            <IonToolbar>
                                <IonButtons slot="end">
                                    <IonButton color="tertiary" expand="full" onClick={gotoTommorow}>
                                        Next Day
                                    </IonButton>
                                </IonButtons>
                                <IonButtons slot="start">
                                    <IonButton color="tertiary" expand="full" onClick={gotoYesterday}>
                                        Previous Day
                                    </IonButton>
                                </IonButtons>

                            </IonToolbar>
                        </IonCard>

                    </IonCol>}
                </IonRow>
                <IonRow>
                </IonRow>
            </IonGrid>
        </Content>
    </IonContent>
}
export default ArchiveDetail;