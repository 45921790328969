import { IonContent } from '@ionic/react';
import React, { FC } from 'react';
import Spacer from './Spacer';




const Content: FC<any> = (props) => {
    const { children, style } = props;
  //   const stylesContent = `
  //   ::-webkit-scrollbar {
  //     width: 20px;
  //   }

  //   /* Track */
  //   ::-webkit-scrollbar-track {
  //     box-shadow: inset 0 0 5px grey; 
  //     border-radius: 10px;
  //   }

  //   /* Handle */
  //   ::-webkit-scrollbar-thumb {
  //     background: red; 
  //     border-radius: 10px;
  //   }

  //   /* Handle on hover */
  //   ::-webkit-scrollbar-thumb:hover {
  //     background: #b30000; 
  //   }
  // `;
    return <IonContent style={style} className={'sadfam-content ' + props.classname && props.classname} {...props}>
        <Spacer height={50} />
        {children}
        <Spacer height={100} />
    </IonContent>

}


export default Content;
