import produce from 'immer';
import create from 'zustand';
import { ArtistId } from '../../models/Artist';
import { FreshNotice, Notice, NoticeId } from '../../models/Notice';
import { NotificationService } from '../../services/NotificationService';

export type NotificationState = {
  notifications: Notice[]
  post: (notice: FreshNotice) => void,
  clear: (id: ArtistId) => void
  remove: (id: NoticeId) => void
  observe: (id: ArtistId) => void
}


export const useNotifications = create<NotificationState>((set, get) => ({
  notifications: [],
  observe: (id) => {
    NotificationService.observe(id, (notices: Notice[]) => {
      const state = get();
      const stateWithUptoDateNotifications = produce(state, (draftstate => {
        draftstate.notifications = notices;
        return draftstate;
      }));
      set(stateWithUptoDateNotifications);
    })
  },
  post: (notice) => {
    NotificationService.post(notice);
  },
  clear: (id) => {
    NotificationService.clear(id);
  },
  remove: (id) => {
    NotificationService.remove(id);
  }
}));
