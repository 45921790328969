import React, { useState } from "react";
import { IonText } from "@ionic/react";
import "./TextSlice.scss";

interface TextSliceProps {
  text: string;
  maxLength: number;
}

const TextSlice: React.FC<TextSliceProps> = ({ text, maxLength }) => {
  const [textLength, setTextLength] = useState(maxLength);
  const isDescriptionExist = !!text.length;
  const isCanExpand = textLength <= text.length;
  const isExpand = textLength === text.length;

  const handleExpandText = () => {
    if (isExpand) {
      setTextLength(maxLength);
    } else {
      setTextLength(text.length);
    }
  };

  const getTextSlice = () => {
    return isExpand || !isCanExpand
      ? text
      : text.slice(0, textLength).split(" ").slice(0, -1).join(" ");
  };

  return (
    <IonText>
      {getTextSlice()}
      {isCanExpand && isDescriptionExist && !isExpand ? " ... " : " "}

      <IonText
        class="expandItem"
        onClick={handleExpandText}
        color="tertiary"
        style={{ letterSpacing: 0.25, fontFamily: "appfont" }}
      >
        {isDescriptionExist &&
          (isCanExpand && !isExpand ? (
            "(expand description)"
          ) : isExpand ? (
            <>
              <br />
              (collapse description)
            </>
          ) : (
            ""
          ))}
      </IonText>
    </IonText>
  );
};
export default TextSlice;
