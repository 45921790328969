import {
  IonAvatar,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCol,
  IonLoading,
  IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, useLocation, withRouter } from "react-router";
import AsyncArtistButton from "../components/AsyncArtistButton";
import Content from "../components/Content";
import { useConfig } from "../data/store/useConfig";
import { useProfile } from "../data/store/useProfile";
import { useRoles } from "../data/store/useRoles";
import HostConfig from "../HostConfig";
import { LoginService } from "../services/LoginService";
import "./Login.scss";

interface OwnProps extends RouteComponentProps {}

interface LoginProps extends OwnProps, RouteComponentProps {}

const Login: React.FC<LoginProps> = () => {
  const { search } = useLocation();
  const [target] = useState<string>(search.slice(2, search.length));
  const { authorId } = useProfile();
  const { role } = useRoles();
  const status = useProfile((x) => x.status);
  const authenticated = status === "authenticated";
  const isAlumni = useConfig((x) => x.isAlumni());

  useEffect(() => {
    if (authenticated && target.length > 3) {
      console.log(target);
      window.location.href = decodeURI(target);
    }
  });

  return (
    <Content style={{ "--background": "#519f6b" }}>
      <IonCardHeader>
        {
          <IonAvatar style={{ width: 108, height: 108, margin: "auto" }}>
            <img
              src="/assets/icon/apple-touch-icon.png"
              alt={HostConfig.title}
            />
          </IonAvatar>
        }
      </IonCardHeader>
      <IonCardContent>
        <IonRow>
          <IonCol sizeMd="6" pushMd="3">
            {status === "unauthenticated" && (
              <IonButton
                color="primary"
                onClick={() => {
                  LoginService.login();
                }}
                type="submit"
                expand="block"
              >
                Login with Google
              </IonButton>
            )}
            <IonLoading isOpen={status === "unknown"} />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol sizeMd="6" pushMd="3">
            {authenticated && (
              <IonCard>
                <IonCardHeader>
                  Welcome to the school of song{" "}
                  {authorId && <AsyncArtistButton id={authorId} />}
                </IonCardHeader>

                <IonCardContent>
                  {isAlumni && (
                    <IonButton
                      fill="outline"
                      routerLink="/conversations?thread=start"
                    >
                      Conversations
                    </IonButton>
                  )}

                  {isAlumni && (
                    <IonButton fill="outline" routerLink="/sessions">
                      Sessions
                    </IonButton>
                  )}

                  {isAlumni && (
                    <IonButton fill="outline" routerLink="/transmit">
                      Transmit
                    </IonButton>
                  )}

                  <IonButton fill="outline" routerLink="/account">
                    Account
                  </IonButton>

                  <IonButton fill="outline" routerLink="/songs">
                    Songs
                  </IonButton>

                  {role && role.authority && role.authority.includes("admin") && (
                    <IonButton fill="outline" routerLink="/admin">
                      Admin
                    </IonButton>
                  )}
                </IonCardContent>
              </IonCard>
            )}
          </IonCol>
        </IonRow>
      </IonCardContent>
    </Content>
  );
};

export default withRouter(Login);
