import create from "zustand";
import 'firebase/compat/firestore';

import { ArtistId } from "../../models/Artist";
import { ArtistRef } from "../../models/Directory";
import { GroupId } from "../../models/Group";
import { FreshSong, SongId, MediaReference } from "../../models/Song";
import { OwnerId } from "../../models/User";
import {  serverTimestamp } from "firebase/firestore";

import { SongService } from "../../services/SongService";
import { TransmissionService } from "../../services/TransmissionService";

export type Transmission = {
  title: string;
  description: string;
  media: MediaReference;
  collection: "songs" | "avatars" | "wall" | "message" | "art";
  authors: ArtistId[];
  recentUpload?: SongId;
};

export type TransmissionState = {
  todaysTransmission?: SongId | null;
  title: string;
  assignment: string;
  description: string;
  groups: GroupId[];
  media?: MediaReference;
  art?: MediaReference;
  status: "fetching" | "transmitting" | "idle";
  collection?: "songs" | "avatars" | "wall" | "message" | "art";
  authors?: ArtistId[];
  artists?: Record<ArtistId, ArtistRef>;
  isValid: () => boolean;
  fetchTodaysTransmission: (id: OwnerId) => void;
  setAuthors: (ids: ArtistId[]) => void;
  setGroups: (groups: GroupId[]) => void;
  setMedia: (media: MediaReference) => void;
  setArt: (art: MediaReference) => void;
  setDescription: (description: string) => void;
  setTitle: (title: string) => void;
  setAssignment: (assignment: string) => void;
  transmit: (ownerId: string) => void;
  clearTodayTransmission: () => void;
  revoke: (id: SongId) => void;
  aggregate: (ownerId: string) => FreshSong;
};
export const useTransmission = create<TransmissionState>((set, get) => ({
  status: "idle",
  title: " ",
  groups: [],
  assignment: "",
  description: " ",
  revoke: async (id) => {
    await SongService.delete(id);
    set({
      todaysTransmission: null,
      status: "idle",
      title: "",
      description: "",
    });
  },
  clearTodayTransmission: () => {
    set({
      todaysTransmission: null,
      status: "idle",
      title: "",
      description: "",
    });
  },
  isValid: () => {
    const { title, media, groups } = get();
    if (!media) {
      return false;
    }

    return (
      title.length > 0 &&
      media.src.length > 0 &&
      media.type.length > 0 &&
      groups.length > 0
    );
  },
  fetchTodaysTransmission: async (id) => {
    set({ status: "fetching" });
    const todaysTransmission =
      await TransmissionService.FetchTodaysTransmission(id);
    set({ todaysTransmission, status: "idle" });
  },
  setMedia: (media: MediaReference) => {
    set({ media });
  },
  setArt: (art: MediaReference) => {
    set({ art });
  },
  setAssignment: (assignment: string) => {
    set({ assignment });
  },
  setGroups: (groups: GroupId[]) => {
    set({ groups });
  },
  setDescription: (description: string) => {
    set({ description });
  },
  setTitle: (title: string) => {
    set({ title });
  },
  setAuthors: (ids) => {
    set({ authors: ids });
  },
  aggregate: (ownerId) => {
    const { authors, title, media, description, groups, assignment } = get();
    return {
      authors,
      media,
      description,
      ownerId,
      groups,
      assignment,
      title,
      updatedAt: serverTimestamp(),
    } as FreshSong;
  },
  transmit: async (ownerId) => {
    const state = get();
    let transmission = state.aggregate(ownerId);
    if (state.art) transmission.art = state.art;
    if (state.assignment) transmission.assignment = state.assignment;
    set({ status: "transmitting" });
    const song = await SongService.transmit(transmission);
    set({ todaysTransmission: song.id, status: "idle" });
  },
}));
