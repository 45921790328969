import { IonButton, IonCol, IonGrid, IonList, IonLoading, IonRow } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import ArtistItem from '../components/ArtistItem';
import Content from '../components/Content';
import PleaseLoginCard from '../components/PleaseLoginCard';
import RequestAccessCard from '../components/RequestAccessCard';
import { useArtists } from '../data/store/useArtists';
import { useConfig } from '../data/store/useConfig';
import { useProfile } from '../data/store/useProfile';
import { usePageTitle } from '../util/sugar';

interface ArtistListProps { }

const ArtistList: React.FC<ArtistListProps> = () => {
  const { observing, status, observeDirectory, pageSize } = useArtists();
  const roster = useArtists(x => x.roster);
  const empty = roster.length === 0;

  usePageTitle('Artists');

  useEffect(() => {
    !observing && observeDirectory();
  }, [empty, observeDirectory, observing])

  const parentRef = React.useRef<HTMLIonListElement>(null);
  const { members, isAlumni } = useConfig();
  const unauthenticated = useProfile(x => x.status === 'unauthenticated');
  const authorId = useProfile(x => x.authorId);
  const [limit, setLimit] = useState<number>(10);

  if (isAlumni() === false) {
    return (
      <Content>
        <RequestAccessCard authorId={authorId} />
      </Content>
    );
  } else if (unauthenticated) {
    return (
      <Content>
        <PleaseLoginCard action='' />
      </Content>
    )
  }
  return (
    <Content className='chill'>
      <IonGrid>
        <IonRow>
          <IonLoading isOpen={status === 'fetching' || status === 'initial'} />
          <IonCol sizeMd='6' offsetMd='3' sizeXs='12'>
            <IonList ref={parentRef}>
              {roster.filter(x => members.includes(x)).slice(0, limit).map(artistId => (
                <ArtistItem key={artistId} id={artistId} />
              ))}
              {members.length > limit && (
                <IonButton fill='outline' expand='full' onClick={() => setLimit(x => x + pageSize)}>
                  Load More
                </IonButton>
              )}
            </IonList>
          </IonCol>
        </IonRow>
      </IonGrid>
    </Content>
  );
};

export default (ArtistList);
