import React, { useLayoutEffect, useState } from "react";
import {
  IonAlert,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPopover,
} from "@ionic/react";
import { addOutline, listOutline } from "ionicons/icons";

import { menuController } from "@ionic/core";
import { useContextModals } from "../data/store/useContextModals";
import { usePlaylists } from "../data/store/usePlaylists";
import { useProfile } from "../data/store/useProfile";
import { useSongs } from "../data/store/useSongs";

import isEmpty from "lodash.isempty";
import { delay } from "../util/sugar";

interface PopoverState {
  showPopover: boolean;
  event?: Event;
}

interface AvailablePlaylists {
  popoverDismiss: () => void;
  popoverState: PopoverState;
  nowPlayingId?: string;
  onClose?: () => void;
}

const AvailablePlaylists: React.FC<AvailablePlaylists> = ({
  popoverDismiss,
  popoverState,
  nowPlayingId,
  onClose,
}) => {
  const [showAlert, setShowAlert] = useState(false);
  const clear = useContextModals((x) => x.clearSongModalContext);
  const nowPlaying = useSongs((x) => nowPlayingId && x.retrieve(nowPlayingId));
  const { ownerId } = useProfile();
  const { playlists, fetchAll, isInPlaylist, addToPlaylist } = usePlaylists();

  const myPlaylists = Object.values(playlists).filter(
    (playlist) => playlist.ownerId === ownerId
  );

  const closeAndClear = async () => {
    popoverDismiss();
    clear();
    onClose && onClose();
    await menuController.close();
  };

  const addToMyPlaylists = (playlistId: string) => {
    if (nowPlayingId) {
      if (isInPlaylist(playlistId, nowPlayingId)) {
        setShowAlert(true);
        return;
      }
      addToPlaylist(playlistId, nowPlayingId).then(async () => {
        popoverDismiss();
        await delay(500);
        await closeAndClear();
      });
    }
  };

  useLayoutEffect(() => {
    fetchAll();
  }, [fetchAll]);

  if (!nowPlaying) {
    return <></>;
  }

  return (
    <IonPopover
      event={popoverState.event}
      isOpen={popoverState.showPopover}
      onDidDismiss={popoverDismiss}
    >
      <IonList>
        <IonItem routerLink="/playlist-create" onClick={closeAndClear}>
          <IonIcon slot="start" icon={addOutline} />
          <IonLabel>New Playlist</IonLabel>
        </IonItem>

        {!isEmpty(myPlaylists) &&
          myPlaylists.map((list) => (
            <IonItem
              key={list.id}
              detail={false}
              routerLink="#"
              onClick={() => addToMyPlaylists(list.id)}
            >
              <IonIcon slot="start" icon={listOutline} />
              <IonLabel>{list.title}</IonLabel>
            </IonItem>
          ))}
      </IonList>

      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header="Oops..."
        message="Looks like this song is already in the playlist."
        buttons={["OK"]}
      />
    </IonPopover>
  );
};

export default AvailablePlaylists;
