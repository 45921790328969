import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonItemDivider,
  IonRow,
  IonSpinner,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useEffect, useState } from "react";

import AsyncArtistButton from "../components/AsyncArtistButton";
import Config from "../components/Config";
import Content from "../components/Content";
import GroupAdmin from "../components/GroupAdmin";
import RolesPanel from "../components/RolesPanel";
import Issues from "./Issues";

import { useArtists } from "../data/store/useArtists";
import { useConfig } from "../data/store/useConfig";
import { useProfile } from "../data/store/useProfile";
import { useRoles } from "../data/store/useRoles";
import { useToasts } from "../data/store/useToasts";

import { Song } from "../models/Song";
import { OwnerId } from "../models/User";
import { usePageTitle } from "../util/sugar";

import "firebase/compat/firestore";

interface OwnerParams {
  ownerId: OwnerId;
}

const NotAuthorized: React.FC<OwnerParams> = ({ ownerId }) => (
  <IonCard>
    <IonCardHeader color="primary">
      <IonCardTitle>
        <IonTitle>Group Status</IonTitle>
      </IonCardTitle>
    </IonCardHeader>

    <IonCardContent>
      <IonItemDivider color="clear" />
      <IonText>Your account is missing administrative authority.</IonText>
      <IonItemDivider color="clear" />
      <IonText>
        If this is an error, please
        <IonButton routerLink={"/issue/group/" + ownerId}>
          {" "}
          Report an Issue
        </IonButton>
      </IonText>
    </IonCardContent>
  </IonCard>
);

const UsersAdmin: React.FC = () => {
  const { fetchAll, all } = useArtists();

  useEffect(() => {
    fetchAll();
  }, [all, fetchAll]);

  return (
    <IonCard>
      <IonCardHeader color="primary">
        <IonCardTitle>
          <IonTitle>Users</IonTitle>
        </IonCardTitle>
      </IonCardHeader>

      <IonCardContent>
        {all.map((artistId) => (
          <AsyncArtistButton variant="alias" key={artistId} id={artistId} />
        ))}
        <IonItemDivider />
        {all.length} Total
      </IonCardContent>
    </IonCard>
  );
};

const Modify: React.FC = () => {
  const [songs, setSongs] = useState<Song[]>([]);
  const { post } = useToasts();
  const groups = useConfig((x) => x.groups);

  return <Content></Content>;
};

const Admin: React.FC = () => {
  const { ownerId } = useProfile();
  const { role } = useRoles();
  const authority = role && role.authority;
  const isSuperAdmin = authority && authority.includes("super admin");
  const isAdmin = authority && authority.includes("admin");
  const isModerator = authority && authority.includes("moderator");
  const hasDashboard = isSuperAdmin || isAdmin || isModerator;

  const [tab, setTab] = useState<
    "issues" | "groups" | "config" | "roles" | "modify"
  >("issues");
  const [showModify] = useState(false);

  usePageTitle("Admin");

  if (!ownerId || !role) {
    return (
      <Content>
        <IonTitle>
          <IonSpinner name="bubbles" />
        </IonTitle>
      </Content>
    );
  }

  return (
    <Content>
      <IonGrid>
        <IonRow>
          {hasDashboard ? (
            <IonCol size="12" sizeLg="8" offsetLg="2">
              <IonToolbar>
                <IonButton
                  fill="solid"
                  color={tab == "issues" ? "tertiary" : "favorite"}
                  onClick={() => {
                    setTab("issues");
                  }}
                >
                  Issues
                </IonButton>

                {/* <IonButton
                  fill="solid"
                  color={!showUsers ? "tertiary" : "favorite"}
                  onClick={() => {
                    setShowUsers((x) => !x);
                  }}
                >
                  Users
                </IonButton> */}

                {(isSuperAdmin || isAdmin) && (
                  <>
                    <IonButton
                      fill="solid"
                      color={tab == "groups" ? "tertiary" : "favorite"}
                      onClick={() => {
                        setTab("groups");
                      }}
                    >
                      Groups
                    </IonButton>

                    <IonButton
                      fill="solid"
                      color={tab == "roles" ? "tertiary" : "favorite"}
                      onClick={() => {
                        setTab("roles");
                      }}
                    >
                      Permissions
                    </IonButton>

                    <IonButton
                      fill="solid"
                      color={tab == "config" ? "tertiary" : "favorite"}
                      onClick={() => {
                        setTab("config");
                      }}
                    >
                      Public Config
                    </IonButton>
                  </>
                )}
              </IonToolbar>
              {tab === "groups" && <GroupAdmin />}
              {/* {showUsers && <UsersAdmin />} */}
              {tab == "issues" && <Issues />}
              {tab == "config" && <Config />}
              {tab == "modify" && <Modify />}
              {tab == "roles" && <RolesPanel />}
            </IonCol>
          ) : (
            <IonCol size="12" sizeLg="8" offsetLg="2">
              {ownerId ? (
                <NotAuthorized ownerId={ownerId} />
              ) : (
                <IonButton routerLink="/login">Login</IonButton>
              )}
            </IonCol>
          )}
        </IonRow>
      </IonGrid>
    </Content>
  );
};

export default Admin;
