import produce from 'immer';
import create from 'zustand';
import { ArtistRef } from '../../models/Directory';
import { SearchService } from '../../services/SearchService';

export interface ArtistSearchResult {
  id: string;
  alias: string;
  avatar: string;
}

export interface SongSearchResult {
  id: string;
  title: string;
  artists: ArtistRef[];
}

export type SearchState = {
  query?: string;
  artists: ArtistSearchResult[];
  songs: SongSearchResult[];
  title: string;
  nbPages: number;
  setTitle: (title: string) => void;
  search: (query: string, index?: string, page?: number) => void;
  clear: () => void;
}


export const useSearch = create<SearchState>((set, get) => ({
  query: '',
  songs: [],
  artists: [],
  title: 'sadfam',
  nbPages: 0,
  setTitle: (title) => {
    set({ title });
  },
  clear: () => {
    const nextState = produce(get(), (draft) => {
      draft.songs = [];
      draft.artists = [];
      draft.query = undefined;
    })
    set(nextState);
  },
  search: async (query, index, page = 0) => {
    if (index === 'artists' && query && query.length > 0) {
      const { artists, nbPages } = await SearchService.please().searchArtists(query, page);
      const nextState = produce(get(), draft => {
        const artistsList = artists.filter(x => x.alias !== undefined && x.alias.length > 1);
        if (query === get().query) {
          for (const artist of artistsList) {
            if (!draft.artists.some(a => a.id === artist.id)) {
              draft.artists.push(artist);
            }
          }
        } else {
          draft.artists = artistsList;
        }
        draft.songs = [];
        draft.nbPages = nbPages;
      });
      set(nextState);
    } else if (index === 'songs' && query && query.length > 0) {
      const { songs, nbPages } = await SearchService.please().searchSongs(query, page);
      const nextState = produce(get(), (draft) => {
        if (query === get().query) {
          for (const song of songs) {
            if (!draft.songs.some(s => s.id === song.id)) {
              draft.songs.push(song);
            }
          }
        } else {
          draft.songs = songs;
        }
        draft.artists = [];
        draft.nbPages = nbPages;
      });
      set(nextState);
    } else if (query.length > 1) {
      const { nbPages, artists, songs } = await SearchService.please().searchMulti(query, page);
      const nextState = produce(get(), draft => {
        if (query === get().query) {
          for (const song of songs) {
            if (!draft.songs.some(s => s.id === song.id)) {
              draft.songs.push(song);
            }
          }
        } else {
          draft.artists = artists;
          draft.songs = songs;
        }
        draft.nbPages = nbPages;
      });
      set(nextState);
    } else {
      const nextState = produce(get(), draft => {
        draft.artists = [];
        draft.songs = [];
      })
      set(nextState);
    }
    const nextState = produce(get(), draft => {
      draft.query = query;
    })
    set(nextState);
  }
}));
