import React, { useState } from "react";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonInput,
  IonItem,
  IonLabel,
  IonTextarea,
  IonToggle,
} from "@ionic/react";
import { MediaReference } from "../models/Song";
import { useAlbums } from "../data/store/useAlbums";
import { useProfile } from "../data/store/useProfile";
import Uploader from "../components/Uploader";
import { Album } from "../models/Albums";

interface UpdateAlbumProps {
  album: Album;
  onClose: () => void;
}

export const UpdateAlbum = ({ album, onClose }: UpdateAlbumProps) => {
  const { updateAlbum, status } = useAlbums();
  const { authorId, ownerId } = useProfile();
  const [name, setName] = useState<string>(album.title);
  const [description, setDescription] = useState<string>(album.description);
  const [art, setArt] = useState<MediaReference>(album.art);
  const [showArt, setShowArt] = useState<boolean>(album.art && !!album.art.src);
  const [isAlbumVisible, setIsAlbumVisible] = useState<boolean>(
    album.isPrivate
  );
  const [isShowEditForm, setIsShowEditForm] = useState<boolean>(false);
  const isUploadedArt = showArt ? !art.src : false;
  const isBtnDisabled =
    !ownerId ||
    !authorId ||
    isUploadedArt ||
    name === "" ||
    status === "fetching";

  const toggleVisibleEditForm = () => {
    setIsShowEditForm((prevState) => !prevState);
  };

  const toggleShowArt = () => {
    setShowArt((prevState) => !prevState);
    showArt && setArt({} as MediaReference);
  };

  const saveAlbum = async () => {
    await updateAlbum({
      ...album,
      title: name,
      art,
      description,
      isPrivate: isAlbumVisible,
    });
    onClose();
  };

  return isShowEditForm ? (
    <IonCard>
      <IonCardContent>
        <IonItem>
          <IonLabel position="stacked" color="primary">
            Title
          </IonLabel>
          <IonInput
            spellCheck={false}
            autocapitalize="off"
            value={name}
            onIonChange={(e) => setName(e.detail.value!)}
            required
          />
        </IonItem>
        <IonItem>
          <IonLabel position="stacked" color="primary">
            Description
          </IonLabel>
          <IonTextarea
            rows={5}
            autoGrow
            value={description}
            onIonChange={(e) => setDescription(e.detail.value!)}
          />
        </IonItem>

        <IonItem lines="none">
          <IonLabel color="primary">Private album</IonLabel>
          <IonToggle
            checked={isAlbumVisible}
            onClick={() => setIsAlbumVisible((prevState) => !prevState)}
          />
        </IonItem>

        <IonItem>
          <IonLabel color="primary">Upload album art</IonLabel>
          <IonToggle checked={showArt} onClick={toggleShowArt} />
        </IonItem>

        {showArt && (
          <Uploader
            message="select an image file"
            collection={"covers"}
            onComplete={(media) => {
              if (media) {
                setArt(media);
              }
            }}
          />
        )}

        <IonButton
          onClick={saveAlbum}
          disabled={isBtnDisabled}
          class="ion-margin"
        >
          Update
        </IonButton>
        <IonButton
          onClick={toggleVisibleEditForm}
          slot="end"
          color="danger"
          class="ion-margin"
        >
          Cancel Edit
        </IonButton>
      </IonCardContent>
    </IonCard>
  ) : (
    <IonButton
      class="ion-margin-vertical"
      fill="outline"
      expand="full"
      onClick={toggleVisibleEditForm}
    >
      Edit album Information
    </IonButton>
  );
};
