import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCol,
  IonGrid,
  IonItem,
  IonItemDivider,
  IonRow,
  IonText,
  IonTitle
} from '@ionic/react';
import React from 'react';
import { LoginService } from '../services/LoginService';

const PleaseLoginCard: React.FC<{ portal?: string, action: string }> = ({ action, portal = '' }) => {
  return (
    <IonGrid>
      <IonRow>
        <IonCol sizeMd='6' pushMd='3' sizeLg='4' pushLg='4'>
          <IonCard>
            <IonCardHeader color='secondary'>
              <IonItem lines='none' color='clear'>
                <IonTitle color='medium' size='large'>
                  Not Authenticated
                </IonTitle>
              </IonItem>
            </IonCardHeader>
            <IonCardContent style={{ textAlign: 'center', alignItems: 'center' }}>
              <IonItemDivider color='clear' />
              <IonRow className='ion-align-items-center'>
                <IonText>Please</IonText>
                <IonButton onClick={()=>{
                  LoginService.login();
                }} className='ion-margin-horizontal'>
                  Login
                </IonButton>
                {action && (
                  <IonText>To {action}</IonText>
                )}
              </IonRow>
            </IonCardContent>
          </IonCard>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
}
export default PleaseLoginCard;
