import produce from "immer";
import create from "zustand";
import { ArtistId } from "../../models/Artist";
import {
  CommentThread,
  FreshCommentNode,
  ThreadId,
} from "../../models/Comment";
import { ActivityService } from "../../services/ActivityService";
import { CommentThreadService } from "../../services/CommentThreadService";
import ObserverService from "../../services/ObserverService";

export type CommentThreadsState = {
  status: "success" | "failure" | "transacting" | "idle";
  threads: Record<ThreadId, CommentThread>;
  transmit: (id: ThreadId, message: FreshCommentNode) => void;
  deleteComment: (id: ThreadId, timestamp: string) => void;
  updateComment: (id: ThreadId, text: string, timestamp: string) => void;
  retrieve: (id: ThreadId) => CommentThread | undefined;
  observe: (id: ThreadId) => void;
  someOneIsTyping: (id: ThreadId, artistId: ArtistId) => ArtistId | undefined;
  isObserving: (id: ThreadId) => boolean;
  stopObserving: (id: ThreadId) => void;
};

export const useComments = create<CommentThreadsState>((set, get) => ({
  status: "idle",
  threads: {},
  stopObserving: (id) => {
    CommentThreadService.stopObserving(id);
  },
  observe: (id) => {
    CommentThreadService.observe(id, async (thread) => {
      if (id === undefined || thread === undefined) {
        return;
      }
      const { updatedAt, comments, typing } = thread;
      const newThreadStateWithComment = produce(get(), (draftState) => {
        draftState.threads[id] = { id, comments, updatedAt, typing };
      });
      set(newThreadStateWithComment);
    });
  },
  someOneIsTyping: (conversationId, artistId) => {
    const thread = get().retrieve(conversationId);
    if (thread && thread.typing && thread.updatedAt) {
      const { typing } = thread;
      const someoneElseIsTypingArtistId = Object.keys(typing).filter(
        (x) => x !== artistId
      )[0];
      if (
        ActivityService.isRecentActity(typing[someoneElseIsTypingArtistId], 10)
      )
        return someoneElseIsTypingArtistId;
    }
    return undefined;
  },
  isObserving: (id) => {
    return ObserverService.please().exist(id);
  },
  transmit: async (id, message) => {
    set({ status: "transacting" });
    CommentThreadService.transmit(id, message)
      .then(() => {
        set({ status: "success" });
      })
      .catch(() => set({ status: "failure" }));
  },
  deleteComment: (threadId, commentId) => {
    set({ status: "transacting" });
    CommentThreadService.delete(threadId, commentId)
      .then(() => {
        set({ status: "success" });
      })
      .catch(() => set({ status: "failure" }));
  },
  updateComment: (threadId, text, commentId) => {
    set({ status: "transacting" });
    CommentThreadService.update(threadId, text, commentId)
      .then(() => {
        set({ status: "success" });
      })
      .catch(() => set({ status: "failure" }));
  },
  retrieve: (id) => {
    return get().threads[id];
  },
}));
