import { IonModal, IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon, IonContent, IonCard, IonCardContent } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { useHelp } from "../data/store/useHelp";
import React from "react";

export const HelpModal:React.FC=()=> {
const {close,index,isOpen,data} = useHelp();
const title = index.replaceAll("-"," ")
const content = data[index];
console.log(index);
return <IonModal
isOpen={isOpen}
onDidDismiss={() => {
    close();
}}
children={
  <IonPage>
<IonHeader>

  <IonToolbar color='tertiary'>
    <IonTitle>
{title}
    </IonTitle>
    <IonButtons slot='end'>

    <IonButton onClick={()=>{close()}} color='light' fill='clear'>
      <IonIcon icon={closeOutline} />
    </IonButton>
    </IonButtons>
  </IonToolbar>
  </IonHeader>
  <IonContent>
<IonCard>
<IonCardContent>
    {content}
  </IonCardContent>
  </IonCard>
  </IonContent>
<IonButton fill="clear" expand="full" onClick={()=>{close()}}>
OK
</IonButton>
  </IonPage>
}
></IonModal>}
