export const warningPersonalAlbumText =
  'You haven\'t created album yet. To create an album, click the "Create album" button.';
export const warningFavoritesAlbumText =
  "You have not added any albums to your favorites.";
export const warningGlobalAlbumsText = "There are no albums available";

export const warningEmptyPersonalSongs =
  "You haven't uploaded any songs yet. To download audio you need to open the transmit form and fill in the form data";
export const warningEmptyUserSongs =
  "This user has not yet uploaded any available audio files";
export const warningEmptyUserAlbums =
  "This user has not created any available albums yet";

export const warningPersonalPlaylistText =
  'You haven\'t created playlist yet. To create an playlist, click the "Create your playlist" button.';
export const warningGlobalPlaylistText = "There are no playlists available";
