import React, { useState } from "react";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonInput,
  IonLabel,
  IonRow,
  IonToolbar,
} from "@ionic/react";

interface emailInputProps {
  onEmailsAdded: (emails: string[]) => void;
  onClose?: () => void;
}

const EmailInput: React.FC<emailInputProps> = ({
  onEmailsAdded,
  onClose = () => {},
}) => {
  const [emailsText, setEmailsText] = useState("");

  return (
    <IonCard>
      <IonCardContent>
        <IonRow className="ion-align-items-center ion-justify-content-between">
          <IonLabel>Member Email</IonLabel>
        </IonRow>
        <IonInput
          color="dark"
          required
          autofocus
          pattern="email"
          placeholder="example@mail.com"
          value={emailsText}
          onIonChange={(e) => setEmailsText(e.detail.value!)}
        />
      </IonCardContent>
      <IonToolbar>
        <IonButton
          type="submit"
          slot="primary"
          color="favorite"
          disabled={emailsText.length === 0}
          onClick={() => {
            onEmailsAdded(emailsText.split("\n").map((x) => x.toLowerCase()));
          }}
          style={{ margin: 10 }}
        >
          Invite
        </IonButton>
        <IonButton
          slot="primary"
          color="danger"
          onClick={onClose}
          style={{ margin: 10 }}
        >
          Cancel
        </IonButton>
      </IonToolbar>
    </IonCard>
  );
};

export default EmailInput;
