import React, { useEffect, useState } from "react";
import { IonItemGroup, IonList, IonButton } from "@ionic/react";

import { useArtists } from "../data/store/useArtists";
import { useComments } from "../data/store/useComments";
import { useProfile } from "../data/store/useProfile";
import { DirectoryService } from "../services/DirectoryService";

import get from "lodash.get";
import { CommentThread, FreshCommentNode } from "../models/Comment";
import MentionableCommentNode from "./MentionableCommentNode";
import MentionableInput from "./MentionableInput";
import Spacer from "./Spacer";

import "./Menu.css";

interface OwnProps {
  id: string;
}

interface CommentWidgetProps extends OwnProps {}

const renderComments = (
  commentThread: CommentThread,
  numberVisibleComments: number
) => {
  if (!commentThread.comments) return <></>;

  const arrayWithFreshComments = [...commentThread.comments].reverse();
  const sliceArray = arrayWithFreshComments.slice(0, numberVisibleComments);
  const formattedArrayForRender = sliceArray.reverse();

  return formattedArrayForRender.map((comment, index) => (
    <MentionableCommentNode key={index} {...comment} />
  ));
};

const NUMBER_ADDITIONAL_COMMENTS = 5;

const CommentWidget: React.FC<CommentWidgetProps> = ({ id }) => {
  const [numberVisibleComments, setNumberVisibleComments] = useState<number>(3);

  const retrieveArtist = useArtists((x) => x.retrieve);
  const fetchArtist = useArtists((x) => x.fetch);
  const shouldFetchArtist = useArtists((x) => x.shouldFetch);
  const { retrieve, observe, transmit, status } = useComments();
  const { authorId, ownerId, darkMode } = useProfile();

  const commentThread = retrieve(id);
  const isUserLoggedIn = id !== "" && authorId;
  const isShowMoreBtnVisible =
    numberVisibleComments < get(commentThread, "comments.length", 0);

  const handleShowMore = () => {
    setNumberVisibleComments(
      (prevState) => prevState + NUMBER_ADDITIONAL_COMMENTS
    );
  };

  const handleTransmitComment = async (
    freshComment: string,
    mentions: string[]
  ) => {
    if (authorId) {
      shouldFetchArtist(authorId) && fetchArtist(authorId);

      let { alias } = retrieveArtist(authorId) || { alias: "" };

      if (alias === "") {
        const artist = await DirectoryService.fetch(authorId);
        alias = artist.alias;
      }

      let commentNode = {
        threadId: id,
        alias,
        authorId: authorId,
        ownerId,
        text: freshComment,
        mentions,
      } as FreshCommentNode;

      transmit(id, commentNode);
    } else {
      console.error("Please Authenticate");
    }
  };

  useEffect(() => {
    observe(id);
  }, [id, observe]);

  return (
    <IonItemGroup
      className="comment-widget"
      color="light"
      style={{ background: darkMode ? "rgb(35, 35, 35)" : "#f4f5f8" }}
    >
      <IonList className="comments">
        {isShowMoreBtnVisible && (
          <IonButton
            size="small"
            fill="clear"
            style={{ marginLeft: 6 }}
            onClick={handleShowMore}
          >
            Show more comments
          </IonButton>
        )}

        {commentThread && renderComments(commentThread, numberVisibleComments)}

        {!authorId && <Spacer height={10} />}

        {isUserLoggedIn && (
          <MentionableInput
            status={status}
            id={id}
            onInActive={() => {}}
            onActive={() => {}}
            onTransmit={handleTransmitComment}
          />
        )}
      </IonList>
    </IonItemGroup>
  );
};

export default CommentWidget;
