import sha1 from "crypto-js/sha1";
import 'firebase/compat/firestore';
import { Artist, ArtistId } from "../models/Artist";
import { Song } from "../models/Song";
import { EndOfTime, Timestamp } from "../models/Timeline";
import { OwnerId } from "../models/User";
import handleError from "../util/handleError";
import { SongService } from "./SongService";

import 'firebase/compat/firestore';
import firebase from "firebase/compat/app";
const firestore = firebase.firestore

export class ArtistService {
  /**
   * Fetch latest song uploaded by artist
   * @param ownerId
   */
  static fetchLatestSong(ownerId: OwnerId): Promise<Song> {
    const db = firestore();
    return new Promise((resolve, reject) => {
      db.collection("songs")
        .where("ownerId", "==", ownerId)
        .orderBy("updatedAt")
        .limitToLast(1)
        .get()
        .then((songDocs) => {
          const latestSongData = songDocs.docs.pop();
          if (latestSongData) {
            resolve(SongService.StronglyTypeSongData(latestSongData));
          } else {
            resolve({} as Song);
          }
        })
        .catch(reject);
    });
  }

  static transmitArtist = (artist: Artist) => {
    var db = firestore();
    return db.collection("artists").doc(artist.id).set(artist);
  };
  /**
   * fetch artist with meta data
   * @param id artist id
   */
  static fetch = (id: string) => {
    return new Promise<Artist>((resolve, reject) => {
      var db = firestore();
      if (typeof id === undefined || id === "" || typeof id != "string") {
        return reject('bad artist id');
      }
      db.collection("artists")
        .doc(id)
        .get()
        .then((artistDoc) => {
          
          const artist = ArtistService.StronglyTypeArtistData(artistDoc);
          if (artistDoc.exists) {
            resolve(artist);
          } else {
            reject({message:"not-found"});
          }
        });
    });
  };

  /**
   * fetch artist with meta data
   * @param ownerId artist Ownerid
   */
  static fetchByOwnerId = (ownerId: string) => {
    return new Promise<Artist>((resolve, reject) => {
      var db = firestore();
      if (
        typeof ownerId === undefined ||
        ownerId === "" ||
        typeof ownerId != "string"
      ) {
        console.log("bad owner id");
        return;
      }
      db.collection("artists")
        .where("ownerId", "==", ownerId)
        .limit(1)
        .get()
        .then((artistDocs) => {
          const artistDoc = artistDocs.docs.pop();
          if (!artistDoc) {
            reject("error");
          } else {
            const artist = { ...artistDoc.data(), id: artistDoc.id } as Artist;
            if (artist) {
              resolve(artist);
            }
          }
        })
        .catch(handleError);
    }).catch(handleError);
  };

  /**
   * Fetch some songs
   *
   * @param artistId the artist ID
   * @param limit how many songs to get
   */
  static fetchSomeSongs = (artistId: ArtistId, limit: number) => {
    console.log("Getting songs for artist" + artistId);
    return new Promise<Song[]>((resolve) => {
      var db = firestore();
      db.collection("songs")
        .where("authors", "array-contains", artistId)
        .orderBy("updatedAt")
        .limitToLast(limit)
        .get()
        .then((querySnapshot) => {
          const songBatch = querySnapshot.docs.map((songDoc) => {
            return SongService.StronglyTypeSongData(songDoc);
          });
          resolve(songBatch);
        });
    });
  };

  static fetchArtistSongsNumber = (artistId: ArtistId) => {
    return new Promise<number>((resolve, reject) => {
      var db = firestore();
      db.collection("songs")
        .where("authors", "array-contains", artistId)
        .get()
        .then((data) => {
          const songsNumber = data.docs.length || 0;
          resolve(songsNumber);
          return songsNumber;
        })
        .catch(reject);
    });
  };

  /**
   * Fetch more songs of a particular artist
   *
   * @param artistId the artist ID
   * @param timestamp timestamp of an old song to start from
   * @param limit how many songs to get
   */
  static fetchMoreSongs = (
    artistId: ArtistId,
    timestamp: Timestamp,
    limit: number
  ) => {
    if (timestamp === EndOfTime || timestamp.seconds === -1) {
      return ArtistService.fetchSomeSongs(artistId, limit);
    }
    return new Promise<Song[]>((resolve, reject) => {
      var db = firestore();
      console.log("ITS HAPPENEING", timestamp);

      db.collection("songs")
        .where("authors", "array-contains", artistId)
        .orderBy("updatedAt")
        .endBefore(timestamp)
        .limitToLast(limit)
        .get()
        .then((querySnapshot) => {
          console.log(querySnapshot);
          const songBatch = querySnapshot.docs.map((songDoc) => {
            return SongService.StronglyTypeSongData(songDoc);
          });
          console.log(songBatch);
          resolve(songBatch);
        })
        .catch(reject);
    });
  };

  /**
   * Calculate Arist ID using SHA1 hash
   * Will we ever run into collisions?
   * think about migrating to another function to avoid them....
   * @param limit
   * @param dispatch
   */
  static calculateArtistId(email: string): string {
    console.log("calculating artist id")
    return sha1(email).toString().substring(0, 11);
  }

  static StronglyTypeArtistData = (
    artistDoc:
      | firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
      | firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
  ) => {
    const artist_data = { ...artistDoc.data(), id: artistDoc.id } as Artist;
    return artist_data;
  };
}
