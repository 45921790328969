import React, { useState } from "react";
import { useHistory } from "react-router";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonInput,
  IonItem,
  IonLabel,
  IonTextarea,
  IonRow,
  IonCol,
  IonToggle,
  IonLoading,
} from "@ionic/react";
import { MediaReference } from "../models/Song";
import { useAlbums } from "../data/store/useAlbums";
import { useProfile } from "../data/store/useProfile";
import Content from "../components/Content";
import Uploader from "../components/Uploader";
import { usePageTitle } from "../util/sugar";

export const CreateAlbum: React.FC = () => {
  const { push } = useHistory();
  const { transmit, status } = useAlbums();
  const { authorId, ownerId } = useProfile();
  const [name, setName] = useState("");
  const [art, setArt] = useState<MediaReference>({} as MediaReference);
  const [showArt, setShowArt] = useState(false);
  const [isAlbumVisible, setIsAlbumVisible] = useState(false);
  const [description, setDescription] = useState("");
  const [isBtnDisable, setIsBtnDisable] = useState(false);
  const isUploadedArt = showArt ? !art.src : false;
  const isBtnDisabled =
    !ownerId || !authorId || isUploadedArt || name === "" || isBtnDisable;

  usePageTitle("Create album");

  const toggleShowArt = () => {
    setShowArt((prevState) => !prevState);
    showArt && setArt({} as MediaReference);
  };

  const saveAlbum = () => {
    setIsBtnDisable(true);
    transmit(ownerId, name, description, art, isAlbumVisible, authorId!).then(
      (id) => {
        push(`/albums/${id}`);
      }
    );
  };

  return (
    <Content>
      <IonRow>
        <IonCol sizeMd="12" sizeLg="8" offsetMd="2" offsetLg="2">
          <IonCard>
            <IonCardContent>
              <IonItem>
                <IonLabel position="stacked" color="primary">
                  Title
                </IonLabel>
                <IonInput
                  spellCheck={false}
                  autocapitalize="off"
                  onIonChange={(e) => setName(e.detail.value!)}
                  required
                />
              </IonItem>
              <IonItem>
                <IonLabel position="stacked" color="primary">
                  Description
                </IonLabel>
                <IonTextarea
                  rows={5}
                  autoGrow
                  onIonChange={(e) => setDescription(e.detail.value!)}
                />
              </IonItem>

              <IonItem lines="none">
                <IonLabel color="primary">Private album</IonLabel>
                <IonToggle
                  checked={isAlbumVisible}
                  onClick={() => setIsAlbumVisible((prevState) => !prevState)}
                />
              </IonItem>

              <IonItem>
                <IonLabel color="primary">Upload album art</IonLabel>
                <IonToggle checked={showArt} onClick={toggleShowArt} />
              </IonItem>

              {showArt && (
                <Uploader
                  message="select an image file"
                  collection={"covers"}
                  onComplete={(media) => {
                    if (media) {
                      setArt(media);
                    }
                  }}
                />
              )}
              <IonButton
                onClick={saveAlbum}
                disabled={isBtnDisabled}
                style={{ margin: 10 }}
              >
                Save
              </IonButton>

              <IonButton
                onClick={() => push("/albums/")}
                slot="end"
                color="danger"
                style={{ margin: 10 }}
              >
                Cancel
              </IonButton>
            </IonCardContent>
          </IonCard>
        </IonCol>
      </IonRow>

      <IonLoading isOpen={status === "fetching"} />
    </Content>
  );
};

export default CreateAlbum;
