import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonList,
  IonProgressBar,
  IonRow,
} from "@ionic/react";
import { arrowDownOutline, playOutline } from "ionicons/icons";
import React, { useState } from "react";
import { usePlayer } from "../data/store/usePlayer";
import { usePlaylist } from "../data/store/usePlaylist";
import { songHookStatus, useSongs } from "../data/store/useSongs";
import { SongRef } from "../models/Song";
import MediaService from "../services/MediaService";
import CompactSongItem from "./CompactSongItem";
import Spacer from "./Spacer";

interface OwnProps {
  songRefs: SongRef[];
  fetchMore: () => void;
  status: songHookStatus;
  compact?: boolean;
  withLimit?: boolean;
  nextPageText?: string;
  hideFetchButton?:boolean;
}

interface SongListProps extends OwnProps {}

const CompactSongList: React.FC<SongListProps> = ({
  songRefs,
  status,
  fetchMore,
  nextPageText,
  withLimit = true,
hideFetchButton=false
}) => {
  const parentRef = React.useRef<HTMLIonListElement>(null);
  const { queueSong, setNowPlaying } = usePlaylist();
  const isPaused = usePlayer((x) => x.isPaused);
  const retrieve = useSongs((x) => x.retrieve);
  const [limit, setLimit] = useState<number>(20);

  const handleFetchMore = () => {
    if (withLimit) {
      songRefs.length > limit ? setLimit((x) => x + 20) : fetchMore();
    } else {
      fetchMore();
    }
  };

  const playAll = () => {
    if (isPaused) {
      const first = retrieve(songRefs[0].id);
      first &&
        MediaService.please()
          .setSong(first)
          .then(() => {
            first && setNowPlaying(first.id);
            MediaService.please().play();
          });
    }
    songRefs.forEach((x) => queueSong(x.id));
  };

  return (
    <IonGrid style={{margin:0}}>
      <IonList className="ion-no-margin" ref={parentRef} style={{ background: "clear",margin:0 }} color={"light"}>
      {songRefs.map((songRef)=><CompactSongItem key={songRef.id} id={songRef.id} />)}
      </IonList>
      {songRefs.length !== 0 && (
        <IonRow>
          <IonCol size="6">
            <IonButton
              fill="outline"
              color="favorite"
              expand="full"
              onClick={playAll}
            >
              Play All
              <IonIcon icon={playOutline} />
            </IonButton>
          </IonCol>
          {hideFetchButton===false&&<IonCol size="6">            
            {status === "idle" ? (
              <IonButton
                fill="outline"
                color="tertiary"
                expand="full"
                onClick={handleFetchMore}
              >
                {nextPageText || "Load More "}
                <IonIcon icon={arrowDownOutline} />
              </IonButton>
            ) : (
              <IonProgressBar
                style={{ height: 52 }}
                type="indeterminate"
                color="tertiary"
              />
            )}
          </IonCol>}
          <Spacer height={100}/>
        </IonRow>
      )}
    </IonGrid>
  );
};

export default CompactSongList;
