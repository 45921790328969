import React, { useEffect, useState } from 'react'

import {
  IonBadge,
  IonButton,
  IonCard,
  IonCardContent, IonProgressBar, IonText
} from '@ionic/react'
import { useArtists } from '../data/store/useArtists'
import { useConfig } from '../data/store/useConfig'
import { useProfile } from '../data/store/useProfile'
import { ArtistId } from '../models/Artist'
import { LoginService } from '../services/LoginService'
import AsyncArtistButton from './AsyncArtistButton'

const defaultMessage =
  "You haven't joined any active groups yet. To join a group, find the invitation link & passcode to your group (this was sent out in an email from School of Song and also is listed on your course information page)."

const RequestAccessCard: React.FC<{
  authorId?: ArtistId
  message?: string
}> = ({ message }) => {
  const { authenticated } = useProfile()
  const loginStatus = useProfile((x) => x.status)
  const id = useProfile((x) => x.authorId)
  const membership = useConfig((x) => x.activeGroups())
  const inactiveGroups = useConfig((x) => x.inactiveGroups())
  const { } = useConfig()

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 3000)
  }, [])
  if (loginStatus !== 'unknown' && !authenticated) {
    return (
      <IonCard className='ion-text-center'>
        <IonCardContent>

          <IonButton onClick={LoginService.login}>
            Login with google
          </IonButton>
        </IonCardContent>
      </IonCard>
    )
  }
  return (
    <IonCard>
      <IonCardContent>
        {loading ? (
          <>
            Loading <IonProgressBar type="indeterminate" />
          </>
        ) : (
          <>
            {id && <AsyncArtistButton id={id} />}
            <IonBadge color="success">
              {membership.length} active groups
            </IonBadge>{' '}
            <IonBadge color="warning">
              {inactiveGroups.length} inactive groups
            </IonBadge>{' '}
            <IonCardContent>
              <IonText color="dark">
                {membership.length === 0 && defaultMessage}
              </IonText>
            </IonCardContent>
          </>
        )}
      </IonCardContent>
    </IonCard>
  )
}

export default RequestAccessCard
