import React, { useEffect, useRef, useState } from "react";
import Linkify from "react-linkify";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonSpinner,
  IonText,
  IonTextarea,
} from "@ionic/react";
import { closeOutline, paperPlane } from "ionicons/icons";

import { useActivity } from "../data/store/useActivity";
import { useConversations } from "../data/store/useConversations";
import { useProfile } from "../data/store/useProfile";

import { ArtistId } from "../models/Artist";
import { FreshMessageNode, MessageNode } from "../models/Message";

import AsyncArtistButton from "./AsyncArtistButton";
import ConversationDeleteModal from "./ConversationDeleteModal";
import { usePageTitle } from "../util/sugar";
import "./styles.scss";

interface MessageWidgetProps {
  id: string;
}

const renderMessages = (messages: MessageNode[]) => {
  return messages.map((message, index) => {
    if (message.text.length === 0) {
      return <React.Fragment key={index} />;
    }

    return (
      <IonItem
        class="ion-no-padding"
        className="message-item"
        key={message.text + index}
        lines="none"
        detail={false}
        color="light"
      >
        <div
          style={{
            margin: "5px 5px auto",
          }}
        >
          <AsyncArtistButton variant="avatar" id={message.authorId} />
        </div>

        <div className="message-details">
          <div>
            <AsyncArtistButton
              id={message.authorId}
              key={index}
              variant="song-alias"
            />

            <IonText className="message-timestamp" color="medium" mode="ios">
              {message.timestamp &&
                `(${new Date(+message.timestamp).toLocaleTimeString([], {
                  month: "long",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                })})`}
            </IonText>
          </div>

          <IonLabel
            style={{ display: "inline" }}
            className="ion-text-wrap message-label"
          >
            <Linkify>{message.text}</Linkify>
          </IonLabel>
        </div>
      </IonItem>
    );
  });
};

const someOneIsTypingMessage = (id: ArtistId) => (
  <IonItem key={"someone-is-typing"} lines="none" detail={false} color="light">
    <IonButtons slot="end">
      <IonLabel className="ion-text-wrap">
        <IonSpinner name="dots" />
      </IonLabel>
    </IonButtons>
    <IonButtons slot="end">
      <AsyncArtistButton variant="avatar" id={id} />
    </IonButtons>
  </IonItem>
);

const MessageWidget: React.FC<MessageWidgetProps> = ({ id }) => {
  const conversation = useConversations((x) => x.retrieve(id));
  const [removeConversationModal, setRemoveConversationModal] = useState(false);
  const { transmitActivity } = useActivity();
  const someOneIsTyping = useConversations((x) => x.someOneIsTyping);
  const { transmit, leave } = useConversations();
  const { authorId, darkMode, ownerId } = useProfile();
  const messages = (conversation && conversation.messages) || [];

  const [messagesPerPage, setMessagesPerPage] = useState(10);
  const [freshMessage, setMessage] = useState("");
  const conversationContentRef = useRef<HTMLIonContentElement>(null);
  const messageTextAreaRef = useRef<HTMLIonTextareaElement>(null);
  const newMessageRef = useRef<HTMLIonItemElement>(null);

  const messagesSlice = messages.slice(-messagesPerPage);
  const memberTypingId = authorId && someOneIsTyping(id, authorId);
  const convoTitle = conversation ? conversation.title : "loading";
  const convoLength = conversation ? conversation.members.length : 0;

  usePageTitle(convoTitle);

  const handleTransmitMessage = () => {
    const messageNode = {
      id,
      authorId: authorId,
      ownerId,
      text: freshMessage,
    } as FreshMessageNode;

    if (conversation) {
      transmit(conversation.id, messageNode);
      setMessage("");
    }
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLIonTextareaElement>) => {
    if (e.keyCode === 13) {
      //Enter
      handleTransmitMessage();
    }

    if (e.keyCode === 32) {
      //Space
      authorId &&
        transmitActivity("typing", "conversations", id, authorId, true);
    }
  };

  const handleBlur = () => {
    setTimeout(() => {
      authorId &&
        transmitActivity("typing", "conversations", id, authorId, false);
    }, 3000);
  };

  useEffect(() => {
    setTimeout(() => {
      conversationContentRef.current &&
        conversationContentRef.current.scrollToBottom(1000);
    }, 1000);
  }, [messages.length]);

  return (
    <IonContent ref={conversationContentRef}>
      <IonItem lines="none" color="light">
        <IonButtons slot="end">
          <IonButton
            slot="end"
            className="ion-float-right"
            size="large"
            onClick={() => setRemoveConversationModal(true)}
          >
            <IonIcon
              className="--ion-padding"
              size="large"
              color={"dark"}
              icon={closeOutline}
            />
          </IonButton>

          {removeConversationModal && (
            <ConversationDeleteModal
              onComplete={() => setRemoveConversationModal(false)}
              id={id}
            />
          )}
        </IonButtons>
      </IonItem>

      <IonList style={{ background: darkMode ? "#333" : "#fff" }}>
        {messages.length >= messagesPerPage && (
          <IonButton
            color="primary"
            expand="full"
            onClick={() => setMessagesPerPage((prevState) => prevState + 10)}
          >
            Show Older
          </IonButton>
        )}

        {messages && renderMessages(messagesSlice)}

        {memberTypingId && someOneIsTypingMessage(memberTypingId)}

        {id !== "" && (
          <IonItem
            color="light"
            lines="none"
            ref={newMessageRef}
            key={id + "-newMessage"}
          >
            {convoLength !== 1 && (
              <IonTextarea
                className="message-textarea"
                inputmode="text"
                autoGrow
                ref={messageTextAreaRef}
                onBlur={handleBlur}
                key={id + "-fresh-message"}
                onKeyUp={(e) => {
                  handleKeyUp(e);
                }}
                onIonChange={(e) => {
                  setMessage(e.detail.value!);
                }}
                value={freshMessage}
                placeholder="message..."
              />
            )}

            <IonButton
              disabled={!(freshMessage && authorId)}
              onClick={() => {
                handleTransmitMessage();
              }}
              slot="end"
              style={{ margin: "auto 5px 15px" }}
              fill="clear"
            >
              <IonIcon size="large" icon={paperPlane} />
            </IonButton>

            {convoLength === 1 && (
              <IonButton
                routerLink={"/conversations?thread=start"}
                onClick={() => {
                  conversation &&
                    ownerId &&
                    authorId &&
                    leave(ownerId, authorId, conversation.id);
                }}
              >
                Leave conversation
              </IonButton>
            )}
          </IonItem>
        )}

        <IonItem lines="none" color="clear" />
        <IonItem lines="none" color="clear" />
        <IonItem lines="none" color="clear" />
      </IonList>
    </IonContent>
  );
};

export default MessageWidget;
