import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  IonButtons,
  IonItem,
  IonLabel,
  IonList,
  IonSearchbar,
  useIonViewWillLeave,
} from "@ionic/react";
import { useProfile } from "../data/store/useProfile";
import { ArtistSearchResult, useSearch } from "../data/store/useSearch";
import { ArtistId } from "../models/Artist";
import ArtistSearchResultButton from "./ArtistSearchResultButton";
import AsyncArtistButton from "./AsyncArtistButton";
import "react-dropzone-uploader/dist/styles.css";

interface ArtistSelectorProps {
  members: ArtistId[];
  placeholder: string;
  fieldName?: string;
  setMembers: Dispatch<SetStateAction<string[]>> | any;
  onSelect?: (artist: ArtistSearchResult) => void;
  hideSearch?: boolean;
  hideMembers?: boolean;
  songAuthorId?: string;
}

const ArtistSelector: React.FC<ArtistSelectorProps> = ({
  placeholder,
  fieldName,
  members,
  setMembers,
  hideSearch,
  hideMembers,
  onSelect,
  songAuthorId,
}) => {
  const { authorId } = useProfile();
  const [selectedMembers, setSelectedMembers] = useState<ArtistId[]>(members);
  const { clear, search, artists, query } = useSearch();

  useIonViewWillLeave(() => {
    clear();
  });

  const removeMember = (id: ArtistId) => {
    if (id === (songAuthorId || authorId)) {
      return;
    }
    setSelectedMembers((members) => members.filter((x) => x !== id));
  };

  useEffect(() => {
    setMembers(selectedMembers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMembers]);

  useEffect(() => {
    return () => clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <IonList>
      {!hideSearch && (
        <IonItem key="search">
          <IonSearchbar
            value={query}
            onIonChange={(e) => search(e.detail.value!, "artists")}
            placeholder={placeholder}
          />
        </IonItem>
      )}

      {query && (
        <IonItem>
          {artists &&
            artists
              .slice(0, 4)
              .filter((x) => !selectedMembers.includes(x.id))
              .map((artist) => {
                return (
                  <ArtistSearchResultButton
                    key={artist.id}
                    artist={artist}
                    onClick={() => {
                      setSelectedMembers((x) => [...x, artist.id]);
                      onSelect && onSelect(artist);
                    }}
                  />
                );
              })}
        </IonItem>
      )}

      {!hideMembers && (
        <IonItem>
          <IonLabel color="primary">
            {fieldName ? fieldName : placeholder}
          </IonLabel>
          <IonButtons>
            {selectedMembers &&
              selectedMembers.map &&
              selectedMembers
                .slice()
                .reverse()
                .map((id) => {
                  return (
                    <AsyncArtistButton
                      id={id}
                      disabled={true}
                      key={id}
                      onClick={() => {
                        removeMember(id);
                      }}
                    ></AsyncArtistButton>
                  );
                })}
          </IonButtons>
        </IonItem>
      )}
    </IonList>
  );
};

export default ArtistSelector;
