import { IonCol, IonGrid, IonRow, IonSpinner } from "@ionic/react";
import React, { FC } from "react";
import { useHistory } from "react-router";
import Content from "../components/Content";
import { LoginService } from "../services/LoginService";
import { delay, usePageTitle } from "../util/sugar";

const Logout: FC = () => {
    usePageTitle("Logging Out");
    const history = useHistory();
    LoginService.logout().then(async () => {
        await delay(1000);
        history.push("/mission");
        window.location.reload();
    });
    return <Content>
        <IonGrid>
            <IonRow className='ion-center'>
                <IonCol size="2" offset='5'>
                    <IonSpinner style={{ width: '100%', height: '100%', margin: 'auto' }} name='crescent' />
                </IonCol>
            </IonRow>
        </IonGrid>
    </Content >
}
export default Logout