import { menuController } from "@ionic/core";
import {
  IonButton,
  IonCheckbox,
  IonCol,
  IonItem,
  IonItemGroup,
  IonLabel,
  IonList,
  IonListHeader,
  IonText
} from "@ionic/react";
import { addOutline, checkmarkOutline } from "ionicons/icons";
import React, { useState } from "react";

import { useAssignments } from "../data/store/useAssignments";
import { useConfig } from "../data/store/useConfig";

import isEmpty from "lodash.isempty";
import { useProfile } from "../data/store/useProfile";
import { getIsChecked, trimToTen } from "../util/sugar";

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth();
const MONTHS_LIST = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const YEARS_LIST = [currentYear - 1, currentYear];

const AssignmentSelector: React.FC = () => {
  const { groups } = useConfig();
  const allGroups = Object.values(groups).filter(x => x.active);
  const { assignmentsInGroup } = useAssignments();
  const [activeGroup, setActiveGroup] = useState<string[]>([]);
  const [activeAssignment, setActiveAssignment] = useState<string[]>([]);
  const isOneGroupSelected = activeGroup.length === 1;
  const areAssignmentsEmpty = isEmpty(activeAssignment);

  const changeGroup = (groupId: string) => () => {
    setActiveGroup([groupId])
  };


  const { authenticated } = useProfile();
  if (!authenticated) {
    return null;
  }
  return (
    <IonCol>
      <IonListHeader >
        <IonLabel color="primary">Groups</IonLabel>
      </IonListHeader>

      {!isEmpty(allGroups) &&
        allGroups.map((item, i) => {
          const { id, active, title } = item;
          const isChecked = getIsChecked(item.id, activeGroup);
          const groupAssignments = assignmentsInGroup(item.id);
          const titleColor = active ? "dark" : "medium";
          const checkBoxColor =
            areAssignmentsEmpty || activeGroup.length > 1 ? "dark" : "medium";
          const itemTitle = active ? title : `${title} (inactive)`;
          const disabled = !isChecked && activeGroup.length === 10;
          const shouldDisplayAssignments =
            !isEmpty(groupAssignments) && isChecked && isOneGroupSelected;

          return (
            <IonItemGroup key={i}>
              <IonItem key={id}>
                <IonLabel color={titleColor}>{itemTitle}</IonLabel>
                <IonCheckbox
                  onClick={changeGroup(id)}
                  color={checkBoxColor}
                  checked={isChecked}
                  disabled={disabled}
                />
              </IonItem>

              {shouldDisplayAssignments && (
                <IonList>
                  {groupAssignments.map((assignment) => {
                    const isMaxAssigmentNumber =
                      activeAssignment.length === 10;
                    const isAssignmentChecked = getIsChecked(
                      assignment.id,
                      activeAssignment
                    );
                    return (
                      <IonItem
                        detail={false}
                        key={assignment.id}
                        onClick={() => {
                          menuController.close("sortSongs");
                        }}
                        color='light'
                        routerLink={"/assignment-songs/" + item.id + "/" + assignment.id}
                        disabled={
                          !isAssignmentChecked && isMaxAssigmentNumber
                        }
                      >
                        <IonButton fill='clear' color='tertiary'>
                          {assignment.title}
                        </IonButton>
                      </IonItem>
                    );
                  })}
                </IonList>
              )}
            </IonItemGroup>
          );
        })}

      {/* <IonItem class="ion-no-padding">
          <IonLabel color="primary">Sort by date</IonLabel>
          <IonCheckbox
            disabled={isRandomSongs || sortByLowComments}
            onClick={() => {
              setSortByDate((prevState) => !prevState);
            }}
            checked={sortByDate}
          />
        </IonItem> */}

      {/* {sortByDate && (
          <>
            <IonRadioGroup
              value={formatDateSorting}
              onIonChange={(e) => setFormatDateSorting(e.detail.value)}
            >
              <IonItem>
                <IonLabel>Newer</IonLabel>
                <IonRadio mode="md" color="dark" slot="end" value="new" />
              </IonItem>

              <IonItem>
                <IonLabel>Older</IonLabel>
                <IonRadio mode="md" color="dark" slot="end" value="old" />
              </IonItem>
              <IonItem>
                <IonLabel>Specify date</IonLabel>
                <IonRadio mode="md" color="dark" slot="end" value="specify" />
              </IonItem>
            </IonRadioGroup>

            {formatDateSorting === "specify" && (
              <IonList class="ion-margin-start">
                {YEARS_LIST.map((item) => {
                  const isYearChecked = specifiedDate.year === item;

                  return (
                    <IonButton
                      key={item}
                      size="small"
                      color={isYearChecked ? "success" : "medium"}
                      onClick={() =>
                        setSpecifiedDate((prevState) => {
                          return { ...prevState, year: item };
                        })
                      }
                    >
                      {item}
                      <IonIcon
                        size="small"
                        slot="start"
                        icon={isYearChecked ? checkmarkOutline : addOutline}
                      />
                    </IonButton>
                  );
                })}
                {MONTHS_LIST.map((item, index) => {
                  const isMonthChecked = specifiedDate.month === index;

                  return (
                    <IonButton
                      key={item}
                      size="small"
                      color={isMonthChecked ? "success" : "medium"}
                      onClick={() =>
                        setSpecifiedDate((prevState) => {
                          return { ...prevState, month: index };
                        })
                      }
                    >
                      {item}
                      <IonIcon
                        size="small"
                        slot="start"
                        icon={isMonthChecked ? checkmarkOutline : addOutline}
                      />
                    </IonButton>
                  );
                })}
              </IonList>
            )}
          </>
        )} */}

      {/* <IonItem class="ion-no-padding">
          <IonLabel color="primary">Show low comments songs</IonLabel>
          <IonToggle
            disabled={isRandomSongs || sortByDate}
            onClick={() => {
              setSortByLowComments((prevState) => !prevState);
            }}
            checked={sortByLowComments}
          />
        </IonItem> */}

      {/* <IonItem class="ion-no-padding">
          <IonLabel color="primary">Favorite songs</IonLabel>
          <IonToggle
            checked={isShowFavorites}
            onClick={() => {
              setIsShowFavorites((prevState) => !prevState);
            }}
          />
        </IonItem> */}
      {/* <IonItem class="ion-no-padding">
          <IonLabel color="primary">Random songs</IonLabel>
          <IonToggle
            checked={isRandomSongs}
            onClick={() => {
              setIsRandomSongs((prevState) => !prevState);
              setSortByLowComments(false);
              setSortByDate(false);
            }}
          />
        </IonItem> */}

      {/* <IonButton
          expand="block"
          color="danger"
          class="ion-margin-top"
          style={{
            borderRadius: 5,
          }}
          onClick={handleResetFilter}
        >
          Reset Fields
        </IonButton> */}
    </IonCol>
  );
};

export default AssignmentSelector;
