import 'firebase/compat/firestore';
import { Configuration } from "../models/Configuration";
import { GroupId } from "../models/Group";

import 'firebase/compat/firestore';
import firebase from "firebase/compat/app";
const firestore = firebase.firestore



export class ConfigurationService {
    static changeConfiguration = (update: any, groupId: GroupId): Promise<Configuration> => {
        return new Promise<Configuration>((resolve, reject) => {
            const db = firestore();
            const configurationDbRef = db.collection('configuration').doc(groupId);
            configurationDbRef.set(update, { merge: true }).then(() => {
                configurationDbRef.get().then(configDoc => {
                    return resolve(configDoc.data() as Configuration);
                }).catch(reject);
            }).catch(reject);
        });
    }
    static observe = (onConfigChange: (config: Configuration[]) => void) => {
        const db = firestore();
        db.collection("configuration").onSnapshot((configDoc) => {
            const configurations = configDoc.docs.map(x => ConfigurationService.StronglyTypeConfigData(x))
            onConfigChange(configurations);
        })

    }

    static StronglyTypeConfigData = (configDoc: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData> | firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>) => {
        const config_data = { ...configDoc.data(), id: configDoc.id } as Configuration;
        return config_data;
    }



}
