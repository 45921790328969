import React, { useEffect, useLayoutEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import {
  IonButton,
  IonButtons,
  IonCol,
  IonInput,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonLoading,
  IonRow,
  IonText,
  IonTextarea,
} from "@ionic/react";
import { musicalNoteOutline } from "ionicons/icons";

import { useProfile } from "../data/store/useProfile";
import { useToasts } from "../data/store/useToasts";
import { useSongs } from "../data/store/useSongs";
import Content from "./Content";
import ConvertingUploader from "./ConvertingUploader";
import ArtistSelector from "./ArtistSelector";

import isEmpty from "lodash.isempty";
import { getPermission } from "../util/sugar";
import { useRoles } from "../data/store/useRoles";

import "react-dropzone-uploader/dist/styles.css";
import Uploader from "./Uploader";
import { MediaReference } from "../models/Song";

interface EditForm { }

const EditForm: React.FC<EditForm> = () => {
  const { id } = useParams<{ id: string }>();
  const { push } = useHistory();
  const { fetch, updateSong, status, songs } = useSongs();
  const { ownerId, authorId } = useProfile();
  const { post } = useToasts();
  const { role } = useRoles();
  const song = songs[id];
  const authors = song && song.authors;

  const [songTitle, setSongTitle] = useState(song && song.title);
  const [songDescription, setSongDescription] = useState(
    song && song.description
  );
  const [songMedia, setSongMedia] = useState(song && song.media);
  const [songAuthors, setSongAuthors] = useState<string[]>([]);
  const [art, setArt] = useState<MediaReference|undefined>();
  const isDisableSaveBtn = isEmpty(songTitle) || isEmpty(songMedia.src);
  const hasPermission = getPermission(role.authority);

  const handleRedirect = () => {
    push("/song/" + song.id);
  };

  const handleSetAuthors = (songAuthors: string[]) => {
    if (songAuthors.includes(song.authors[0])) {
      setSongAuthors(songAuthors);
    } else {
      setSongAuthors([...authors, ...songAuthors]);
    }
  };

  const handleToggleMedia = () => {
    if (songMedia && songMedia.src) {
      setSongMedia({
        src: "",
        type: "",
      });
    } else {
      setSongMedia(song.media);
    }
  };

  useLayoutEffect(() => {
    if (!song) {
      fetch(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [song]);

  useEffect(() => {
    if (song && song.authors && isEmpty(songAuthors[0])) {
      setSongAuthors(song.authors);
    }
  }, [authorId, songAuthors, song]);

  useEffect(() => {
    if (song) {
      const { title, description, media, authors, art } = song;
      setSongTitle(title);
      setSongDescription(description);
      setSongAuthors(authors);
      setSongMedia(media);
      art && setArt(art as any);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [song]);

  if (!song || status === "fetching") {
    return <IonLoading isOpen />;
  }

  return (ownerId && ownerId === song.ownerId) || hasPermission ? (
    <Content className="chill">
      <IonRow>
        <IonCol sizeMd="6" pushMd="3">
          <IonList className="inner-chill">
            {!isEmpty(songAuthors) && (
              <ArtistSelector
                songAuthorId={authors[0]}
                placeholder={"Collaborators"}
                fieldName={"Authors"}
                members={songAuthors}
                setMembers={handleSetAuthors}
              />
            )}

            <IonItem>
              <IonLabel position="stacked" color="primary">
                Title
              </IonLabel>
              <IonInput
                spellCheck={false}
                autocapitalize="off"
                value={songTitle}
                onIonChange={(e) => setSongTitle(e.detail.value!)}
                required
              />
            </IonItem>

            <IonItem>
              <IonLabel position="stacked" color="primary">
                Description
              </IonLabel>
              <IonTextarea
                rows={5}
                autoGrow
                value={songDescription}
                onIonChange={(e) => setSongDescription(e.detail.value!)}
              />
            </IonItem>

            <ConvertingUploader
              collection={"songs"}
              initialAudio={song}
              onToggle={handleToggleMedia}
              onComplete={(media) => {
                const pathParts = media.src.split(".");
                const extension = pathParts[pathParts.length - 1].toLowerCase();
                if (
                  extension === "aif" ||
                  extension === "aaif" ||
                  extension === "aiif" ||
                  extension === "wav" ||
                  extension === "flac"
                ) {
                  alert("UNSUPPORTED FILE TYPE");
                  document.location.reload();
                  return;
                }
                if (media) setSongMedia(media);
              }}
            />
          </IonList>
          <IonItem>
                {<Uploader
                  message="select your song artwork"
                  collection="avatar"
                  onComplete={(media) => {
                    setArt(media);
                  }}
                />}
                <IonButtons slot={'end'}>
  <img src={art?.src}/>
                </IonButtons>
              </IonItem>

          <IonItemDivider color="clear" />

          <IonRow>
            <IonCol>
              <IonButton
                disabled={isDisableSaveBtn}
                expand="block"
                onClick={() => {
                  post({
                    id: "saved-song",
                    message: "Saved",
                    icon: musicalNoteOutline,
                  });
                  updateSong(song.id, {
                    ...song,
                    title: songTitle,
                    description: songDescription,
                    authors: songAuthors,
                    media: songMedia,
                    art,
                  });
                  handleRedirect();
                }}
              >
                Save
              </IonButton>
            </IonCol>
          </IonRow>
        </IonCol>
      </IonRow>
    </Content>
  ) : (
    <Content>
      <IonText color={"danger"}>
        You do not have permission to view the content of this page
      </IonText>
    </Content>
  );
};

export default EditForm;
