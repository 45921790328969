import { IonCol, IonGrid, IonLoading, IonRow } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import Content from '../components/Content';
import PleaseLoginCard from '../components/PleaseLoginCard';
import RequestAccessCard from '../components/RequestAccessCard';
import SongList from '../components/SongList';
import { useConfig } from '../data/store/useConfig';
import { useProfile } from '../data/store/useProfile';
import { useSongs } from '../data/store/useSongs';
import { useIdParam, usePageTitle } from '../util/sugar';




const OldGroupSongsPage: React.FC = (props) => {
    const id = useIdParam(props);
    const { observe } = useSongs()
    const { authorId } = useProfile();
    const unauthenticated = useProfile(x => x.status === "unauthenticated")
    const authenticated = useProfile(x => x.status === "authenticated");
    const fetchSome = useSongs(x => x.fetchSome);
    const status = useSongs(x => x.status);
    const songs = useSongs(x => x.timeline)
    const membership = [id];
    const hasGroups = true
    const loadingGroups = useConfig(x => typeof x.membership === "undefined")
    const retrieveSong = useSongs(x => x.retrieve);
    const groupSongs = songs.filter((songRef) => {
        const song = retrieveSong(songRef.id)
        return song && song.groups.includes(id)
    });
    usePageTitle("Session Songs");
    const [fetchedInitial, setFetchedInitial] = useState(false)
    useEffect(() => {
        if (authenticated && hasGroups && membership.includes(id) && id.length > 0 && !fetchedInitial) {
            fetchSome([id]);
            observe([id]);
            setFetchedInitial(true);
        }
    }, [authenticated, fetchSome, hasGroups, id, membership, observe, fetchedInitial])

    if (!hasGroups && !loadingGroups) {
        return <Content>
            <RequestAccessCard authorId={authorId} />
        </Content>
    } else if (unauthenticated) {
        return <Content><PleaseLoginCard action="" /></Content>
    }
    return (
        <Content className='no-scroll'>
            <IonLoading isOpen={status === "initial"} />
            <IonGrid>
                <IonRow>
                    {<IonCol sizeMd="8" sizeLg="6" offsetMd="2" offsetLg="3">
                        <SongList
                            status={status}
                            fetchMore={() => fetchSome([id])}
                            songRefs={groupSongs}
                        />
                    </IonCol>}
                </IonRow>
                <IonRow>
                </IonRow>
            </IonGrid>
        </Content>
    );
};

export default OldGroupSongsPage;