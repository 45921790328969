import create from "zustand";
import { Song } from "../../models/Song";

export type ContextActions = {
  modalSong: Song;
  isShowSongModal: boolean;
  setSong: (modalSong: Song) => void;
  showSongModal: (boolean: boolean) => void;
  clearSongModalContext: () => void;
};

export const useContextModals = create<ContextActions>((set) => ({
  isShowSongModal: false,
  modalSong: {} as Song,
  showSongModal: (boolean) => {
    set({ isShowSongModal: boolean });
  },
  setSong: (modalSong: Song) => {
    set({ modalSong });
  },
  clearSongModalContext: () => {
    set({ modalSong: {} as Song });
  },
}));
