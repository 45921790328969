import React, { useEffect, useState } from "react";
import { RouteComponentProps, useHistory, useLocation } from "react-router";
import {
  IonButton,
  IonButtons,
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonList,
  IonLoading,
  IonModal,
  IonRow,
  IonSplitPane,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";

import AsyncArtistButton from "../components/AsyncArtistButton";
import ConversationDeleteModal from "../components/ConversationDeleteModal";
import ConversationStarter from "../components/ConversationStarter";
import MessageWidget from "../components/MessagesWidget";

import { useConversations } from "../data/store/useConversations";
import { useProfile } from "../data/store/useProfile";
import { useSearch } from "../data/store/useSearch";

import { useSearchParam } from "../util/sugar";
import useWindowSize from "../util/useWindowSize";
import isEmpty from "lodash.isempty";
import "../components/styles.scss";
import "react-dropzone-uploader/dist/styles.css";
import { app } from "../services/LoginService";
import firebase from 'firebase/compat/app';

interface ConversationsProps extends RouteComponentProps { }

const Conversations: React.FC<ConversationsProps> = (props) => {
  const {
    conversations,
    all,
    status,
    observe,
    activeConversation,
    setActiveConversation,
  } = useConversations();
  const setTitle = useSearch((x) => x.setTitle);
  const { ownerId, authorId } = useProfile();

  const [showConversationsModal, setShowConversationsModal] = useState(false);
  const [removeConversationId, setRemoveConversationId] = useState("");
  const { width } = useWindowSize();
  const history = useHistory();
  const location = useLocation();
  const id = useSearchParam("thread");

  const md = width > 600;

  const handleOpenConversation = (id: string) => {
    setShowConversationsModal(false);
    history.push("conversations?thread=" + id);
  };
  useIonViewWillEnter(() => {
    firebase.analytics(app).logEvent("view_conversations")
  }, [])


  useEffect(() => {
    if (ownerId) {
      observe(ownerId);
    }
  }, [ownerId, observe]);

  useEffect(() => {
    if (!location.pathname.includes("conversations")) {
      return;
    }

    if (!id && conversations && all.length > 0) {
      history.push({ search: "?thread=" + all[0] });
    } else if (!id && conversations && all.length === 0) {
      history.push({ search: "?thread=start" });
    }
  }, [
    activeConversation,
    all,
    ownerId,
    conversations,
    history,
    id,
    observe,
    setActiveConversation,
    setTitle,
    location.pathname,
  ]);

  const conversationList = () =>
    all &&
    all.map((conversationId) => {
      const convo = conversations[conversationId];
      const messages = convo.messages;
      const lastMessage = messages[messages.length - 1];

      return (
        convo && (
          <IonItemSliding key={conversationId}>
            <IonItem
              color={convo.id === id ? "middle" : "light"}
              key={convo.id}
              onClick={() => handleOpenConversation(convo.id)}
              button
              detail={true}
            >
              <IonRow
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <IonLabel>{convo.title}</IonLabel>
                <IonLabel
                  style={{
                    fontSize: 12,
                    marginTop: 5,
                  }}
                >
                  {!isEmpty(lastMessage.authorId) && (
                    <>
                      <AsyncArtistButton
                        id={lastMessage.authorId}
                        variant="message-alias"
                      />
                      {lastMessage.text}
                    </>
                  )}
                </IonLabel>
              </IonRow>

              <IonButtons
                slot="end"
                style={{
                  padding: 5,
                }}
              >
                {md &&
                  convo.members
                    .filter((x) => x !== authorId)
                    .slice(0, 4)
                    .map((memberId) => {
                      return (
                        <div key={memberId}
                          style={{
                            margin: "0 2px",
                          }}
                        >
                          <AsyncArtistButton
                            disabled
                            key={memberId}
                            variant="avatar"
                            id={memberId}
                          />
                        </div>
                      );
                    })}

                {convo.members.filter((x) => x !== authorId).length > 4
                  ? "+ " + (convo.members.length - 3)
                  : ""}
              </IonButtons>
            </IonItem>

            <IonItemOptions side="start">
              <IonItemOption
                color="danger"
                onClick={() => {
                  setRemoveConversationId(convo.id);
                }}
              >
                <IonIcon icon={closeOutline} />
              </IonItemOption>
            </IonItemOptions>
          </IonItemSliding>
        )
      );
    });

  if (status !== "idle") {
    return <IonLoading isOpen={true} />;
  }

  return (
    <div>
      {removeConversationId !== "" && (
        <ConversationDeleteModal
          onComplete={() => {
            setRemoveConversationId("");
          }}
          id={removeConversationId}
        />
      )}

      <IonSplitPane when={true} contentId="convo">
        {md && (
          <IonList className={"conversation-list"}>
            <IonItem lines="none" color="clear" />
            <IonItem
              color={"start" === id ? "primary" : "light"}
              button
              onClick={() => {
                setActiveConversation("start");
                history.push({ search: "?thread=start" });
              }}
            >
              <IonLabel>Start Conversation</IonLabel>
            </IonItem>
            {conversationList()}
          </IonList>
        )}

        <div id="convo">
          {!md && (
            <IonToolbar style={{ position: "fixed", top: 43 }}>
              <IonButtons slot="start">
                <IonButton onClick={() => setShowConversationsModal(true)}>
                  Conversations
                </IonButton>
              </IonButtons>
              <IonButtons slot="end">
                <IonButton
                  onClick={() => {
                    history.push("conversations?thread=start");
                  }}
                >
                  New
                </IonButton>
              </IonButtons>

              <IonModal
                isOpen={showConversationsModal}
                onDidDismiss={() => {
                  setShowConversationsModal(false);
                }}
              >
                <IonList>
                  <IonItem
                    color={"start" === id ? "primary" : undefined}
                    routerLink="/conversations?thread=start"
                    onClick={() => setShowConversationsModal(false)}
                  >
                    <IonLabel>Start Conversation</IonLabel>
                  </IonItem>
                  {conversationList()}
                </IonList>
              </IonModal>
            </IonToolbar>
          )}
          <IonItem />

          {id && id !== "start" ? (
            <MessageWidget id={id} />
          ) : (
            <ConversationStarter />
          )}
        </div>
      </IonSplitPane>
    </div>
  );
};

export default Conversations;
