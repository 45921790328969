import 'firebase/compat/firestore';
import { FavoriteAlbumsRef, FollowRef, SongRef } from "../models/Song";
import { OwnerId } from "../models/User";
import 'firebase/compat/firestore';
import firebase from "firebase/compat/app";
const firestore = firebase.firestore


export type ArtistFavoriteList = {
  favorites: SongRef[];
  id: OwnerId;
};

export type ArtistFollowList = {
  follow: FollowRef[];
  id: OwnerId;
};

export type ArtistFavoriteAlbumsList = {
  favoriteAlbums: FavoriteAlbumsRef[];
  id: OwnerId;
};

export class FavoriteService {
  static changeFavorites = (
    update: ArtistFavoriteList
  ): Promise<ArtistFavoriteList> => {
    const ownerId = update.id;
    return new Promise<ArtistFavoriteList>((resolve, reject) => {
      const db = firestore();
      const favoritesDbRef = db.collection("favorites").doc(ownerId);
      favoritesDbRef
        .set(update, { merge: true })
        .then(() => {
          favoritesDbRef
            .get()
            .then((favoritesDoc) => {
              const favorites =
                FavoriteService.StronglyTypeFavoriteData(favoritesDoc);
              return resolve(favorites);
            })
            .catch(reject);
        })
        .catch(reject);
    });
  };
  static changeFollow = (
    update: ArtistFollowList
  ): Promise<ArtistFollowList> => {
    const ownerId = update.id;
    return new Promise<ArtistFollowList>((resolve, reject) => {
      const db = firestore();
      const followDbRef = db.collection("follow").doc(ownerId);
      followDbRef
        .set(update, { merge: true })
        .then(() => {
          followDbRef
            .get()
            .then((followDoc) => {
              const followList =
                FavoriteService.StronglyTypeFollowData(followDoc) || [];
              return resolve(followList);
            })
            .catch(reject);
        })
        .catch(reject);
    });
  };

 
  // Adds a new favorite artist
  static AddFavorite = (ownerId: string, song: SongRef): Promise<ArtistFavoriteList> => {
    return new Promise<ArtistFavoriteList>((resolve, reject) => {
      const db = firestore();
      const favoritesDbRef = db.collection("favorites").doc(ownerId);

      favoritesDbRef
        .update({
          favorites: firestore.FieldValue.arrayUnion(song)
        })
        .then(() => {
          favoritesDbRef
            .get()
            .then((favoritesDoc) => {
              const favorites = FavoriteService.StronglyTypeFavoriteData(favoritesDoc);
              resolve(favorites);
            })
            .catch(reject);
        })
        .catch(reject);
    });
  };

  // Removes an existing favorite artist
  static RemoveFavorite = (ownerId: string, song: SongRef): Promise<ArtistFavoriteList> => {
    return new Promise<ArtistFavoriteList>((resolve, reject) => {
      const db = firestore();
      const favoritesDbRef = db.collection("favorites").doc(ownerId);

      favoritesDbRef
        .update({
          favorites: firestore.FieldValue.arrayRemove(song)
        })
        .then(() => {
          favoritesDbRef
            .get()
            .then((favoritesDoc) => {
              const favorites = FavoriteService.StronglyTypeFavoriteData(favoritesDoc);
              resolve(favorites);
            })
            .catch(reject);
        })
        .catch(reject);
    });
  };




  static changeFavoriteAlbums = (
    update: ArtistFavoriteAlbumsList
  ): Promise<ArtistFavoriteAlbumsList> => {
    const ownerId = update.id;
    return new Promise<ArtistFavoriteAlbumsList>((resolve, reject) => {
      const db = firestore();
      const favoritesDbRef = db.collection("favoriteAlbums").doc(ownerId);
      favoritesDbRef
        .set(update, { merge: true })
        .then(() => {
          favoritesDbRef
            .get()
            .then((favoriteAlbumsDoc) => {
              const favoriteAlbums =
                FavoriteService.StronglyTypeFavoriteAlbumsData(
                  favoriteAlbumsDoc
                );

              return resolve(favoriteAlbums);
            })
            .catch(reject);
        })
        .catch(reject);
    });
  };

  static fetchArtistFavoriteList = (
    ownerId: OwnerId
  ): Promise<ArtistFavoriteList> => {
    return new Promise<ArtistFavoriteList>(async (resolve, reject) => {
      const db = firestore();
      db.collection("favorites")
        .doc(ownerId)
        .get()
        .then((favoritesDoc) => {
          const favorites =
            FavoriteService.StronglyTypeFavoriteData(favoritesDoc) || [];
          return resolve(favorites);
        })
        .catch(reject);
    });
  };

  static fetchArtistFavoriteAlbumsList = (
    ownerId: OwnerId
  ): Promise<ArtistFavoriteAlbumsList> => {
    return new Promise<ArtistFavoriteAlbumsList>(async (resolve, reject) => {
      const db = firestore();
      db.collection("favoriteAlbums")
        .doc(ownerId)
        .get()
        .then((favoritesDoc) => {
          const favoriteAlbums =
            FavoriteService.StronglyTypeFavoriteAlbumsData(favoritesDoc) || [];
          return resolve(favoriteAlbums);
        })
        .catch(reject);
    });
  };

  static fetchArtistFollowList = (
    ownerId: OwnerId
  ): Promise<ArtistFollowList> => {
    return new Promise<ArtistFollowList>((resolve, reject) => {
      const db = firestore();
      db.collection("follow")
        .doc(ownerId)
        .get()
        .then((followDoc) => {
          const follow =
            FavoriteService.StronglyTypeFollowData(followDoc) || [];
          return resolve(follow);
        })
        .catch(reject);
    });
  };
  static StronglyTypeFavoriteData = (
    artistDoc:
      | firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
      | firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
  ) => {
    return { ...artistDoc.data(), id: artistDoc.id } as ArtistFavoriteList;
  };
  static StronglyTypeFavoriteAlbumsData = (
    artistDoc:
      | firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
      | firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
  ) => {
    return {
      ...artistDoc.data(),
      id: artistDoc.id,
    } as ArtistFavoriteAlbumsList;
  };
  static StronglyTypeFollowData = (
    artistDoc:
      | firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
      | firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
  ) => {
    return { ...artistDoc.data(), id: artistDoc.id } as ArtistFollowList;
  };
}
