import React, { useLayoutEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCol,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonSpinner,
} from "@ionic/react";

import { useArtists } from "../data/store/useArtists";
import { useRoles } from "../data/store/useRoles";
import { useProfile } from "../data/store/useProfile";
import { RoleType } from "../models/Role";

import get from "lodash.get";
import capitalize from "lodash.capitalize";
import groupBy from "lodash.groupby";

interface ChangeUserRoleProps extends RouteComponentProps {
  id: string;
}

const ChangeUserRole: React.FC<ChangeUserRoleProps> = (props) => {
  const { id } = props;
  const { fetch, status, artists } = useArtists((state) => state);
  let { ownerId } = useProfile();
  const {
    getUsersWithRoles,
    allUsersWithRoles,
    isLoading,
    setUserRole,
    deleteUserRole,
    role,
  } = useRoles();
  const [selected, setSelected] = useState<RoleType>();
  const [isShowSuccessCard, setIsShowSuccessCard] = useState(false);
  const [isShowChangeRoleForm, setIsShowChangeRoleForm] = useState(false);

  const groupedUsersWithRoles = groupBy(allUsersWithRoles, "ownerId");
  // @ts-ignore
  const artistId: string = get(artists, `${id}.ownerId`);
  const userRoles =
    get(groupedUsersWithRoles, `${artistId}[0].authority`) || [];
  const defaultUserRole = userRoles[0] || "member";
  const authority = role && role.authority;
  const isSuperAdmin = authority && authority.includes("super admin");
  const isAdmin = authority && authority.includes("admin");

  const isArtistRoleLowerThanAdmin =
    defaultUserRole !== "super admin" && defaultUserRole !== "admin";
  const rolesForSuperAdmin = isSuperAdmin ? ["super admin", "admin"] : [];
  const availableRoles = [...rolesForSuperAdmin, "moderator", "member"];
  const isBtnDisabled =
    !selected ||
    (!userRoles[0] && selected === "member") ||
    userRoles[0] === selected;

  const openChangeRoleForm = () => {
    setIsShowChangeRoleForm(true);
  };

  const closeChangeRoleForm = () => {
    setIsShowChangeRoleForm(false);
  };

  const closeSuccessCard = () => {
    setIsShowSuccessCard(false);
    setIsShowChangeRoleForm(false);
    getUsersWithRoles();
  };

  const handleChangeRole = () => {
    if (selected && artistId) {
      if (userRoles[0] && selected === "member") {
        deleteUserRole(artistId);
      } else {
        setUserRole(artistId, { authority: [selected], id: artistId });
      }

      setIsShowSuccessCard(true);
    }
  };

  useLayoutEffect(() => {
    fetch(id);
    getUsersWithRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (ownerId === artistId) {
    return <div />;
  }

  if (status === "fetching" || isLoading) {
    return (
      <IonRow className="ion-justify-content-center">
        <IonSpinner name="dots" />
      </IonRow>
    );
  }

  if (isShowSuccessCard) {
    return (
      <IonCol sizeMd="8" sizeLg="6" offsetMd="2" offsetLg="3">
        <IonRow className="ion-justify-content-center">
          <IonCard>
            <IonCardContent
              className="ion-text-center"
              style={{ background: "white", width: "100%" }}
            >
              <IonCardHeader color={"primary"}>
                User role was successfully changed
              </IonCardHeader>

              <IonItemDivider color="clear" />

              <IonItem>{`Current user role: "${selected}"`}</IonItem>

              <IonButton
                slot="start"
                color="success"
                onClick={closeSuccessCard}
              >
                Close
              </IonButton>
            </IonCardContent>
          </IonCard>
        </IonRow>
      </IonCol>
    );
  }

  return (isAdmin && isArtistRoleLowerThanAdmin) || isSuperAdmin ? (
    isShowChangeRoleForm ? (
      <IonCard>
        <IonCardContent
          className="ion-text-center"
          style={{ background: "white" }}
        >
          <IonCardHeader color="primary">Change user role</IonCardHeader>

          <IonRadioGroup
            value={selected || defaultUserRole}
            onIonChange={(e) => setSelected(e.detail.value)}
          >
            {availableRoles.map((item, index) => {
              return (
                <IonItem key={index}>
                  <IonLabel autoCapitalize="characters">
                    {capitalize(item)}
                  </IonLabel>
                  <IonRadio slot="start" value={item} />
                </IonItem>
              );
            })}
          </IonRadioGroup>
          <IonItemDivider color="clear" />

          <IonItem>
            {selected && selected !== userRoles[0]
              ? `Change user role from "${defaultUserRole}" to "${selected}"`
              : `Current user role: "${defaultUserRole}"`}
          </IonItem>

          <IonItemDivider color="clear" />
          <IonButton
            style={{ margin: "0 20px" }}
            slot="start"
            disabled={isBtnDisabled}
            onClick={handleChangeRole}
          >
            Submit
          </IonButton>
          <IonButton
            style={{ margin: "0 20px" }}
            slot="end"
            color="danger"
            onClick={closeChangeRoleForm}
          >
            Close
          </IonButton>
        </IonCardContent>
      </IonCard>
    ) : (
      <IonRow className="ion-justify-content-center">
        <IonButton color="primary" onClick={openChangeRoleForm}>
          Change role
        </IonButton>
      </IonRow>
    )
  ) : (
   <></>
  );
};

export default withRouter(ChangeUserRole);
