import axios from "axios";
import 'firebase/compat/firestore';
import { GroupId } from "../models/Group";
import firebase from 'firebase/compat/app';
const firestore=firebase.firestore


export class InviteService {
    static verifyCode = (groupId: string, authorId: string, code: string) => new Promise<void>((resolve, reject) => {
        const callback = (groupId?: GroupId) => {
            typeof groupId !== "undefined" ? resolve() : reject("Invalid Code");
        }
        console.log(groupId, authorId, code);

        axios.post("/activateCode", { groupId, authorId, code }).then(() => {
            callback(groupId)
        }).catch(({ data }) => {
            callback(undefined);
        })
    })
    static getInviteCode = (groupId: string) => new Promise<string>((resolve, reject) => {
        const db = firestore();
        db.collection("invites").doc(groupId).get().then((inviteDoc) => {
            console.log(inviteDoc.data());
            const { code } = inviteDoc.data() || { code: undefined }
            code ? resolve(code) : reject();
        }).catch(reject)
    })

    static setInviteCode = (groupId: string, ownerId: string, code: string) => new Promise<void>((resolve, reject) => {
        const db = firestore();
        db.collection("invites").doc(groupId).set({ code, groupId }).then(resolve)
            .catch(reject)
    })

}
