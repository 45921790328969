import create from "zustand";
import { GroupId } from "../../models/Group";
import { InviteService } from "../../services/InviteService";

export type InviteCodeState = {
    getInviteCode: (groupId: GroupId, ownerId: string) => Promise<string>,
    setInviteCode: (groupId: GroupId, ownerId: string, code: string) => void,
    validateInviteCode: (groupId: GroupId, authorId: string, code: string) => Promise<void>,
}


export const useInviteCodes = create<InviteCodeState>((set) => ({
    getInviteCode: async (groupId: GroupId) => {
        const code = await InviteService.getInviteCode(groupId)
        return code;
    },
    setInviteCode: (groupId: GroupId, ownerId: string, code: string) => {
        return InviteService.setInviteCode(groupId, ownerId, code)
    },
    validateInviteCode: (groupId: GroupId, authorId: string, code: string) => {
        return InviteService.verifyCode(groupId, authorId, code)
    },
}));