import produce from "immer";
import create from "zustand";
import { ArtistId } from "../../models/Artist";
import {
  Conversation,
  ConversationId,
  FreshMessageNode,
} from "../../models/Message";
import { OwnerId } from "../../models/User";
import { ActivityService } from "../../services/ActivityService";
import { ConversationService } from "../../services/ConversationService";

export type ConversationsState = {
  all: ConversationId[];
  conversations: Record<ConversationId, Conversation>;
  activeConversation?: ConversationId;
  transmit: (convo_id: ConversationId, message: FreshMessageNode) => void;
  leave: (ownerId: string, authorId: string, id: ConversationId) => void;
  mute: (id: ConversationId) => void;
  retrieve: (id: ConversationId) => Conversation | undefined;
  setActiveConversation: (id: ConversationId) => void;
  someOneIsTyping: (
    id: ConversationId,
    artistId: ArtistId
  ) => ArtistId | undefined;
  startConversation: (
    title: string,
    members: ArtistId[],
    message: FreshMessageNode
  ) => void;
  observe: (id: OwnerId) => void;
  clear: () => void;
  status: "initial" | "fetching" | "idle";
};

export const useConversations = create<ConversationsState>((set, get) => ({
  conversations: {},
  all: [],
  status: "initial",

  clear: () => {
    set({ conversations: {} });
  },

  observe: (ownerId) => {
    set({ status: "fetching" });

    ConversationService.observe(ownerId, (convo) => {
      const state = get();
      const stateWithNewMessage = produce(state, (draftstate) => {
        draftstate.conversations[convo.id] = convo;
        if (!draftstate.all.includes(convo.id)) {
          draftstate.all.push(convo.id);
        }
        return draftstate;
      });
      set(stateWithNewMessage);
    });

    set({ status: "idle" });
  },

  someOneIsTyping: (conversationId, artistId) => {
    const thread = get().retrieve(conversationId);
    if (thread && thread.typing && thread.updatedAt) {
      const { typing } = thread;

      const someoneElseIsTypingArtistId = Object.keys(typing).filter(
        (x) => x !== artistId
      )[0];

      if (
        ActivityService.isRecentActity(typing[someoneElseIsTypingArtistId], 10)
      )
        return someoneElseIsTypingArtistId;
    }
    return undefined;
  },

  startConversation: async (title, members, message) => {
    const conversation = await ConversationService.transmitConversation(
      title,
      members,
      message
    );
    get().setActiveConversation(conversation.id);
  },

  transmit: (id, message) => {
    ConversationService.transmit(id, message);
  },

  leave: (ownerId, authorId, conversationId) => {
    ConversationService.leaveConversation(ownerId, authorId, conversationId);
    const state = get();
    const stateWithNewActiveConvo = produce(state, (draftstate) => {
      if (draftstate.activeConversation === conversationId) {
        draftstate.activeConversation = undefined;
      }
      delete draftstate.conversations[conversationId];
      draftstate.all = draftstate.all.filter((x) => x !== conversationId);
    });
    set(stateWithNewActiveConvo);
  },

  mute: (id) => {},

  setActiveConversation: (id) => {
    const state = get();
    const stateWithNewActiveConvo = produce(state, (draftstate) => {
      draftstate.activeConversation = id;
      return draftstate;
    });
    set(stateWithNewActiveConvo);
  },

  retrieve: (id) => {
    return get().conversations[id];
  },
}));
