import React from "react";
import { useHistory, useParams } from "react-router";
import {
  IonAlert,
  IonCol,
  IonGrid,
  IonList,
  IonRow,
  IonButton,
  IonLoading,
} from "@ionic/react";
import { useSongs } from "../data/store/useSongs";
import CompactSongItem from "./CompactSongItem";
import Content from "./Content";

export const SongDelete: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { push } = useHistory();
  const removeSong = useSongs((x) => x.delete);
  const song = useSongs((x) => x.songs[id]);
  const status = useSongs((x) => x.status);

  const handleConfirm = () => {
    push("/");
  };

  return (
    <Content>
      <IonLoading isOpen={status === "deleting"} />
      <IonGrid>
        <IonRow>
          <IonCol sizeMd="8" sizeLg="6" offsetMd="2" offsetLg="3">
            <IonList>
              {song && (
                <>
                  <CompactSongItem id={id} />
                  <IonButton
                    onClick={() => {
                      removeSong(id);
                    }}
                    expand="full"
                    color="danger"
                  >
                    Delete Song FOREVER
                  </IonButton>
                </>
              )}

              <IonAlert
                isOpen={!song}
                onDidDismiss={() => {}}
                header="Attention"
                message="Song was deleted"
                buttons={[
                  {
                    text: "Go to home page",
                    handler: () => {
                      handleConfirm();
                    },
                  },
                ]}
              />
            </IonList>
          </IonCol>
        </IonRow>
      </IonGrid>
    </Content>
  );
};
