import React, { useEffect, useState } from "react";
import { menuController } from "@ionic/core";
import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonLoading,
  IonRow,
  IonCardContent,
  IonLabel,
  IonToggle,
} from "@ionic/react";
import { funnelOutline } from "ionicons/icons";

import Content from "../components/Content";
import PleaseLoginCard from "../components/PleaseLoginCard";
import RequestAccessCard from "../components/RequestAccessCard";
import SongList from "../components/SongList";

import { useConfig } from "../data/store/useConfig";
import { useProfile } from "../data/store/useProfile";
import { useSongs } from "../data/store/useSongs";

import { usePageTitle } from "../util/sugar";
import useWindowSize from "../util/useWindowSize";
import isEmpty from "lodash.isempty";
import SortSongs from "../components/SortSongs";
import CompactSongList from "../components/CompactSongList";
import "../components/styles.scss";
import { CompactToggle } from "../components/CompactToggle";

const UnfilteredSongsPage: React.FC = () => {
  const { authorId, status: userStatus,compact } = useProfile();
  const {
    fetchSome,
    status,
    initialFetchStatus,
    timeline,
  } = useSongs();
  const { isAlumni, activeMemberships } = useConfig();
  const { width } = useWindowSize();



  const membership = activeMemberships();
  const unauthenticated = userStatus === "unauthenticated";
  const authenticated = userStatus === "authenticated";
  const songStatus = !isEmpty(membership) ? status : "idle";
  const lg = width > 767;

  usePageTitle("Songs");


  const handleFetchMore = () => {
       fetchSome(membership);
  };


  useEffect(() => {
    if (
      authenticated &&
      initialFetchStatus === "initial" &&
      !isEmpty(membership)
    ) {
      fetchSome(membership);
    }
  }, [authenticated, initialFetchStatus, membership, fetchSome]);

  if (isAlumni() === false) {
    return (
      <Content>
        <RequestAccessCard authorId={authorId} />
      </Content>
    );
  } else if (unauthenticated) {
    return (
      <Content>
        <PleaseLoginCard action="View Songs" />
      </Content>
    );
  }

  return (
    <Content>
      <IonGrid style={{margin:0,padding:0}}>
        <IonRow>
          <IonCol style={{padding:0,margin:0}} sizeMd="8" sizeLg="6" offsetMd="2" offsetLg="3">
            <IonCardContent
              style={{
                padding: "0px",
                borderRadius: "8px",
                margin: "0 16px",
              }}
            >
            <CompactToggle/>
            </IonCardContent>

            {isEmpty(membership) && (
              <RequestAccessCard message="You have no active classes" />
            )}

            {compact ? (
              <CompactSongList
                songRefs={timeline}
                fetchMore={handleFetchMore}
                status={songStatus}
              />
            ) : (
              <SongList
                status={songStatus}
                fetchMore={handleFetchMore}
                songRefs={timeline}
              />
            )}
          </IonCol>
        </IonRow>
      </IonGrid>
    </Content>
  );
};

export default UnfilteredSongsPage;
