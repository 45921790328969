import React, { useLayoutEffect } from "react";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCol,
  IonIcon,
  IonImg,
  IonItem,
  IonRouterLink,
  IonRow,
  IonToolbar,
  IonText,
  IonLabel,
} from "@ionic/react";
import dayjs from "dayjs";
import {
  addOutline,
  ellipsisHorizontalOutline,
  pause,
  play,
  starOutline,
  starSharp,
} from "ionicons/icons";

import { useAssignments } from "../data/store/useAssignments";
import { useConfig } from "../data/store/useConfig";
import { useContextModals } from "../data/store/useContextModals";
import { useFavorites } from "../data/store/useFavorites";
import { usePlayer } from "../data/store/usePlayer";
import { usePlaylist } from "../data/store/usePlaylist";
import { useProfile } from "../data/store/useProfile";
import { useSongs } from "../data/store/useSongs";
import MediaService from "../services/MediaService";

import { Song } from "../models/Song";
import { Assignment } from "../models/Assignment";
import isEmpty from "lodash.isempty";
import { toDate } from "../util/sugar";
import ArtistButton from "./ArtistButton";
import AsyncArtistButton from "./AsyncArtistButton";
import CommentThread from "./CommentWidget";
import HashTagify from "./Hashtagify";
import TextSlice from "./TextSlice";
import Spacer from "./Spacer";
import useWindowSize from "../util/useWindowSize";

const relativeTime = require("dayjs/plugin/relativeTime");

dayjs.extend(relativeTime);

interface OwnProps {
  id: string;
  clearDeletedSong?: (id: string) => void;
}

interface SongItemProps extends OwnProps { }

const SongItem: React.FC<SongItemProps> = ({ id, clearDeletedSong }) => {
  const { isFavorite, addFavorite, removeFavorite } = useFavorites();
  const showSongModal = useContextModals((x) => x.showSongModal);
  const setSong = useContextModals((x) => x.setSong);
  const { authenticated, authorId, ownerId } = useProfile();
  const { isPaused } = usePlayer();
  const { groups } = useConfig();
  const { assignments } = useAssignments();
  const { fetch, shouldFetch, empty } = useSongs();
  const { setNowPlaying, nowPlayingId, queueSong, songs } = usePlaylist();
  const song = useSongs((x) => x.retrieve(id));
  const { width } = useWindowSize();
  const mobileWith = width < 500;
  const isShouldFetch = shouldFetch(id);

  const isNowPlaying = id === nowPlayingId ? "now-playing" : "";
  const isInPlaylist = song && songs.includes(id);
  const songHeaderClass = "song-item-header " + isNowPlaying;

  const handleOpenModal = (currentSong: Song) => () => {
    setSong(currentSong);
    showSongModal(!!currentSong);
  };

  const addSongToPlaylist = () => {
    console.log(song);
    queueSong(id);
  };

  const pauseNow = () => {
    MediaService.please().pause();
  };

  const playSongNow = () => {
    if (!isInPlaylist) queueSong(id);
    if (song) {
      MediaService.please()
        .setSong(song)
        .then(() => {
          MediaService.please().play();
          setNowPlaying(id);
        });
    }
  };

  useLayoutEffect(() => {
    if (!song && isShouldFetch) {
      fetch(id);
    } else if (!song && empty.includes(id)) {
      clearDeletedSong && clearDeletedSong(id);
    }
  }, [clearDeletedSong, empty, fetch, id, isShouldFetch, shouldFetch, song]);

  if (!song) return <></>;

  if (song.privacy === "member" && !authenticated) {
    return (
      <IonCard className="song-item">
        <IonToolbar className={songHeaderClass} />
        <IonCardContent className="song-item-content">
          The author of this song has marked this as visible to members only.
        </IonCardContent>
        <IonButton fill="outline" routerLink="login">
          login
        </IonButton>
      </IonCard>
    );
  }

  if (song.privacy === "restricted" && !song.authors.includes(authorId || "")) {
    return <></>;
  }

  const group = song.groups.join("");
  const groupAssignments = assignments[group];
  const assignmentId: string = song.assignment || "";
  const assignment: Assignment | undefined =
    groupAssignments && assignmentId
      ? groupAssignments[assignmentId]
      : undefined;
  const date = toDate(song.updatedAt);

  return (
    <IonCard className="song-item">
      <IonToolbar className={songHeaderClass}>
        {!mobileWith && (
          <IonButtons slot="start">
            {song.authors &&
              song.authors.map((authorId, index) =>
                song.artists ? (
                  <ArtistButton
                    artist={song.artists[authorId]}
                    key={index}
                    variant="avatar"
                  />
                ) : (
                  <AsyncArtistButton id={authorId} />
                )
              )}
          </IonButtons>
        )}

        <IonItem
          detail={false}
          lines="none"
          class="ion-no-padding"
          color="clear"
        >
          <IonButtons>
            <IonCol>
              <IonRow>
                <IonButton
                  className="song-title"
                  routerLink={"/song/" + song.id}
                >
                  <IonLabel>{song.title}</IonLabel>
                </IonButton>
              </IonRow>
              <IonRow>
                {song.authors &&
                  song.authors.map((authorId, index) => (
                    <ArtistButton
                      artist={song.artists[authorId]}
                      key={index}
                      variant="song-alias"
                    />
                  ))}
              </IonRow>
            </IonCol>
          </IonButtons>
        </IonItem>

        <IonButtons slot="end">
          <IonCol>
            <IonRow className="ion-justify-content-end">
              <IonText color="medium">
                <small>{date}</small>
              </IonText>
            </IonRow>
            <IonRow
              className="ion-justify-content-end"
              style={{ paddingTop: 4 }}
            >
              <IonRouterLink routerLink={"/session-songs/" + song.groups[0]}>
                <IonText color="medium" className="is-link">
                  <small>
                    {song.groups.map((x) => groups[x] && groups[x].title)}
                  </small>
                </IonText>
              </IonRouterLink>
            </IonRow>

            {!!assignment && (
              <IonRow
                className="ion-justify-content-end"
                style={{ paddingTop: 4 }}
              >
                <IonRouterLink
                  routerLink={`/assignment-songs/${group}/${assignmentId}`}
                >
                  <IonText color="medium" className="is-link">
                    <small>{assignment.title}</small>
                  </IonText>
                </IonRouterLink>
              </IonRow>
            )}
          </IonCol>
        </IonButtons>
      </IonToolbar>

      <IonCardContent className="song-item-content">
        {!isEmpty(song.description) && (
          <pre style={{ whiteSpace: "pre-wrap" }}>
            <HashTagify>
              <TextSlice text={song.description || ""} maxLength={500} />
            </HashTagify>
          </pre>
        )}
        <Spacer height={10} />
        {song.art && (
          <IonImg style={{ margin: "-10px -15px -20px" }} src={song.art.src} />
        )}
      </IonCardContent>

      <IonItem lines="none" className="song-item-content">
        <IonButtons>
          {isNowPlaying && !isPaused && (
            <IonButton size="large" onClick={() => pauseNow()}>
              <IonIcon
                className="--ion-padding"
                size="large"
                color="dark"
                icon={pause}
              />
            </IonButton>
          )}

          {isNowPlaying && isPaused && (
            <IonButton size="large" onClick={() => playSongNow()}>
              <IonIcon
                className="--ion-padding"
                size="large"
                color="dark"
                icon={play}
              />
            </IonButton>
          )}

          {!isNowPlaying && (
            <IonButton size="large" onClick={() => playSongNow()}>
              <IonIcon
                className="--ion-padding"
                size="large"
                color="dark"
                icon={play}
              />
            </IonButton>
          )}

          {!isInPlaylist && (
            <IonButton size="large" onClick={() => addSongToPlaylist()}>
              <IonIcon
                className="--ion-padding"
                size="large"
                color="dark"
                icon={addOutline}
              />
            </IonButton>
          )}
        </IonButtons>

        <IonButtons slot="end">
          {ownerId && song.id && (
            <IonButton
              color="dark"
              onClick={() => {
                !isFavorite(ownerId!, song.id)
                  ? addFavorite(ownerId!, song.id)
                  : removeFavorite(ownerId!, song.id);
              }}
            >
              <IonIcon
                slot="start"
                color={isFavorite(ownerId, song.id) ? "warning" : undefined}
                icon={!isFavorite(ownerId, song.id) ? starOutline : starSharp}
              />
            </IonButton>
          )}
          <IonButton
            slot="end"
            className="ion-float-right"
            size="large"
            onClick={handleOpenModal(song)}
          >
            <IonIcon
              className="--ion-padding"
              size="large"
              color="dark"
              icon={ellipsisHorizontalOutline}
            />
          </IonButton>
        </IonButtons>
      </IonItem>
      <CommentThread id={id} />
    </IonCard>
  );
};

export default SongItem;
