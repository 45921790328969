import produce from "immer";
import create from "zustand";

import { ArtistId } from "../../models/Artist";
import { AssignmentId } from "../../models/Assignment";
import { CommentsDoc } from "../../models/Comment";
import { GroupId } from "../../models/Group";
import {
  Song,
  SongId,
  SongRef,
  FreshSong,
  SongsFilterParams,
} from "../../models/Song";
import { Tag } from "../../models/Tag";
import { EndOfTime, SpecifiedDate, Timestamp } from "../../models/Timeline";

import { ArtistService } from "../../services/ArtistService";
import { SongService } from "../../services/SongService";
import { CommentThreadService } from "../../services/CommentThreadService";
import {
  compareTimestamps,
  shuffle,
  filterSongsByParams,
} from "../../util/sugar";
import isEmpty from "lodash.isempty";

export type songHookStatus = "fetching" | "idle" | "initial" | "deleting";

export type SongDirectory = {
  assignments: Record<AssignmentId, SongRef[]>;
  timeline: SongRef[];
  sortedSongs: SongRef[];
  tagged?: SongRef[];
  archive?: SongRef[];
  artists: Record<ArtistId, SongRef[]>;
  artistNumberSongs: Record<ArtistId, number>;
  cursor: Timestamp;
  pageSize: number;
  songsTotal: number;
  songs: Record<SongId, Song>;
  status: songHookStatus;
  initialFetchStatus: songHookStatus;
  empty: SongId[];
  all: SongId[];
  // revisions: SongRef[]
  observe: (groups: GroupId[]) => void;
  shouldFetch: (id: SongId) => boolean;
  clearSortedSongs: () => void;
  setInitialSortedSongs: () => void;
  retrieve: (id: SongId) => Song | undefined;
  retrieveByArtist: (id: ArtistId) => SongRef[] | undefined;
  fetchSongsForArtist: (id: ArtistId) => void;
  fetchSongsForHashtag: (groups: GroupId[], tag: Tag) => void;
  fetchSongsForDate: (groups: GroupId[], date: string) => void;
  // fetchSongsByParams: (
  //   groups: string[],
  //   assignments: string[],
  //   dateOrder: string,
  //   isShowLowComments: boolean,
  //   specifiedDate: SpecifiedDate,
  //   favorites: SongRef[],
  //   isShowFavorites: boolean,
  //   isRandomSongs: boolean,
  //   randomId: string,
  //   fetchMore?: boolean
  // ) => void;
  songsFilterParams: SongsFilterParams | any;
  fetchSongsForAssignment: (id: string) => void;
  fetch: (id: SongId, toTimeline?: boolean) => void;
  delete: (id: SongId) => void;
  // revise: (id: SongId, description: string, media: MediaReference, title: string) => void
  updateSong: (id: SongId, song: FreshSong) => void;
  fetchSome: (groups: GroupId[]) => void;
  // fetchRevisions: (id: SongId) => void,
  oldest: () => Timestamp;
  oldestFromArtist: (id: ArtistId) => Timestamp;
};

export const useSongs = create<SongDirectory>((set, get) => ({
  assignments: {},
  timeline: [],
  sortedSongs: [],
  archive: [],
  all: [],
  // revisions: [],
  artists: {},
  artistNumberSongs: {},
  songsFilterParams: {} as SongsFilterParams,
  cursor: EndOfTime,
  status: "initial",
  initialFetchStatus: "initial",
  pageSize: 10,
  songsTotal: 0,
  songs: {} as Record<SongId, Song>,
  empty: [],
  delete: (id) => {
    set({ status: "deleting" });
    SongService.delete(id).then(() => {
      const newState = produce(get(), (draft) => {
        draft.timeline = get().timeline.filter((item) => item.id !== id);
        draft.all = get().all.filter((item) => item !== id);
        delete draft.songs[id];
        draft.status = "idle";
      });

      set(newState);
    });
  },

  observe: (groups) => {
    SongService.observe(
      groups,
      (song) => {
        if (song.updatedAt === null) {
          return;
        }
        const nextState = produce(get(), (draftState) => {
          draftState.songs[song.id] = song;

          if (!draftState.timeline.map((x) => x.id).includes(song.id)) {
            draftState.timeline.unshift(SongService.toRef(song));
          }

          draftState.status = "idle";
          draftState.timeline = draftState.timeline.sort(
            (y, x) => x.updatedAt.seconds - y.updatedAt.seconds
          );
        });
        set(nextState);
      },
      (revokedSongId) => {
        const revokedState = produce(get(), (draftState) => {
          delete draftState.songs[revokedSongId];
          draftState.timeline = draftState.timeline.filter(
            (x) => x.id !== revokedSongId
          );
          draftState.all = draftState.all.filter((x) => x !== revokedSongId);
          draftState.status = "idle";
        });
        set(revokedState);
      }
    );
  },

  oldestFromArtist: (id) => {
    const artistSongs = get().artists[id];
    if (artistSongs === undefined || artistSongs.length < 6) {
      return { seconds: -1, nanoseconds: -1 };
    }
    return artistSongs[artistSongs.length - 1].updatedAt;
  },

  // revise: async (id, description, media, revisionTitle) => {
  //   const songRepo = get();
  //   const songs = produce(songRepo.songs, draftState => {
  //     draftState[id].original = id;
  //   });
  //   set({ songs, status: "fetching" });
  //   let revisedSong = get().songs[id];
  //   const { authors, ownerId } = revisedSong;
  //   const title = revisedSong.title + " (" + revisionTitle + ")";
  //   const freshRevisedSong = { description, media, title, authors, ownerId } as FreshSong
  //   SongService.revise(id, freshRevisedSong).then((newRevision) => {
  //     const nextState = produce(get(), draftState => {
  //       draftState.songs[newRevision.id] = newRevision;
  //       draftState.all.push(newRevision.id);
  //       draftState.revisions = [];
  //       draftState.revisions.push(SongService.toRef(revisedSong))
  //       draftState.revisions.push(SongService.toRef(newRevision))
  //       draftState.status = "idle";
  //     })
  //     set(nextState)
  //   }).catch(() => {
  //     set({ status: "idle" });
  //   })
  // },

  updateSong: (id: string, song: FreshSong) => {
    set({ status: "fetching" });

    SongService.updateSong(id, song)
      .then((freshSong) => {
        const nextState = produce(get(), (draftState) => {
          draftState.songs[id] = {
            ...freshSong,
            id,
          };
          draftState.status = "idle";
        });
        set(nextState);
      })
      .catch(() => {
        set({ status: "idle" });
      });
  },

  oldest: () => {
    let timeline = [...get().timeline];
    if (timeline.length === 0) {
      return { seconds: -1, nanoseconds: -1 };
    }
    return timeline.sort((x, y) => x.updatedAt.seconds - y.updatedAt.seconds)[0]
      .updatedAt;
  },

  // fetchRevisions: async (songId) => {
  //   set({ status: "fetching" })
  //   SongService.fetchRevisions(songId).then((songs) => {
  //     const revisions = songs.map(x => SongService.toRef(x));
  //     const nextState = produce(get(), draftState => {
  //       for (const song of songs.reverse()) {
  //         draftState.songs[song.id] = song;
  //       }
  //       draftState.revisions = revisions;
  //       draftState.status = "idle";
  //     })
  //     set(nextState)
  //   }).catch(() => {
  //     set({ status: "idle" });
  //   })
  // },

  fetchSome: async (groups) => {
    const directory = get();
    set({ status: "fetching", initialFetchStatus: "idle" });
    try {
      const songBatch = (await SongService.fetchMore(
        groups,
        directory.pageSize,
        directory.oldest()
      ).then(async (batch) => {
        const enrichedBatch: any = [];

        for (const song of batch) {
          await CommentThreadService.fetch(song.id).then((threadDoc) => {
            const comments = (threadDoc as CommentsDoc).comments || [];
            enrichedBatch.push({ ...song, comments });
          });
        }
        return enrichedBatch;
      })) as Song[];

      const nextState = produce(get(), (draftState) => {
        for (const song of songBatch.reverse()) {
          draftState.songs[song.id] = song;

          if (!draftState.timeline.map((x) => x.id).includes(song.id)) {
            draftState.timeline.push(SongService.toRef(song));
          }

          draftState.timeline = draftState.timeline.sort(
            (y, x) => x.updatedAt.seconds - y.updatedAt.seconds
          );
        }
        draftState.status = "idle";
      });
      set(nextState);
    } catch(e) {
      console.log("error",e);
      set({ status: "idle" });
    }
  },

  fetchSongsForDate: async (groups, date) => {
    set({ status: "fetching" });
    const archiveSongs = await SongService.fetchSongsOnDate(groups, date);
    const archive: SongRef[] = archiveSongs.map(({ id, updatedAt }) => ({
      id,
      updatedAt,
    }));
    const newSongs: Record<SongId, Song> = archiveSongs
      .map((song) => ({ [song.id]: song }))
      .reduce((a, b) => {
        return { ...a, ...b };
      }, {});
    set({ archive, songs: { ...get().songs, ...newSongs }, status: "idle" });
  },

  fetchSongsForHashtag: async (groups, tag) => {
    try {
      const tagged = await SongService.fetchSongsWithHashtag(tag, groups);
      const taggedRefs = tagged.map(({ id, updatedAt }) => ({ id, updatedAt }));
      const nextState = produce(get(), (draftState) => {
        for (const song of tagged) {
          draftState.songs[song.id] = song;
        }
        draftState.tagged = taggedRefs;
        draftState.status = "idle";
      });
      set(nextState);
    } catch {
      set({ status: "idle" });
    }
  },

  fetchSongsForArtist: async (id) => {
    const directory = get();
    set({ status: "fetching" });
    try {
      const songs = (await ArtistService.fetchMoreSongs(
        id,
        directory.oldestFromArtist(id),
        directory.pageSize
      ).then(async (batch) => {
        const enrichedBatch: any = [];

        for (const song of batch) {
          await CommentThreadService.fetch(song.id).then((threadDoc) => {
            const comments = (threadDoc as CommentsDoc).comments || [];
            enrichedBatch.push({ ...song, comments });
          });
        }
        return enrichedBatch;
      })) as Song[];

      const songsNumber = await ArtistService.fetchArtistSongsNumber(id);

      const nextState = produce(get(), (draftState) => {
        if (draftState.artists[id] === undefined) {
          draftState.artists[id] = songs.map((x) => SongService.toRef(x));
        } else {
          for (const song of songs) {
            if (!draftState.artists[id].map((x) => x.id).includes(song.id)) {
              draftState.artists[id].push(SongService.toRef(song));
            }
          }
        }
        draftState.artists[id].sort((x, y) =>
          compareTimestamps(y.updatedAt, x.updatedAt)
        );
        for (const song of songs) {
          draftState.songs[song.id] = song;
        }

        draftState.artistNumberSongs[id] = songsNumber;
        draftState.status = "idle";
      });
      set(nextState);
    } catch {
      set({ status: "idle" });
    }
  },

  fetchSongsForAssignment: async (id) => {
    set({ status: "fetching" });

    const directory = get();
    try {
      const songsForAssignment = (await SongService.fetchSongsForAssignment(
        id
      )) as Song[];

      let songs: any = songsForAssignment;

      // for (const song of songsBatch) {
      //   await CommentThreadService.fetch(song.id).then((threadDoc) => {
      //     songs.push({
      //       ...song,
      //       comments: (threadDoc as CommentsDoc).comments || [],
      //     });
      //   });
      // }

      const nextState = produce(directory, (draftState) => {
        if (draftState.assignments[id] === undefined) {
          draftState.assignments[id] = songs.map((x: any) =>
            SongService.toRef(x)
          );
        } else {
          for (const song of songs) {
            if (!draftState.assignments[id].some((x) => x.id === song.id)) {
              draftState.assignments[id].push(SongService.toRef(song));
            }
          }
        }

        for (const song of songs) {
          draftState.songs[song.id] = song;
        }

        draftState.songsTotal = songsForAssignment.length;
        draftState.status = "idle";
      });
      set(nextState);
    } catch {
      set({ status: "idle" });
    }
  },

  // fetchSongsByParams: async (
  //   groups: string[],
  //   assignments: string[],
  //   dateOrder: string,
  //   isShowLowComments: boolean,
  //   specifiedDate: SpecifiedDate,
  //   favorites: SongRef[],
  //   isShowFavorites: boolean,
  //   isRandomSongs: boolean,
  //   randomId: string,
  //   fetchMore: boolean = false
  // ) => {
  //   set({
  //     status: "fetching",
  //     songsFilterParams: {
  //       groups,
  //       assignments,
  //       dateOrder,
  //       isShowLowComments,
  //       specifiedDate,
  //       favorites,
  //       isShowFavorites,
  //       isRandomSongs,
  //       randomId,
  //     },
  //   });

  //   try {
  //     // When sorting by favorites, we immediately have the entire collection of songs.
  //     // Therefore, we sort without the help of the Firebase API by pure function
  //     if (isShowFavorites && !isEmpty(favorites)) {
  //       const nextState = produce(get(), (draftState) => {
  //         for (const song of favorites) {
  //           draftState.songs[song.id] = {
  //             ...get().songs[song.id],
  //             comments: song.comments,
  //           };
  //         }

  //         const allFavoritesSongs = favorites.map(
  //           (item) => get().songs[item.id]
  //         );

  //         draftState.sortedSongs = filterSongsByParams(
  //           allFavoritesSongs,
  //           groups,
  //           assignments,
  //           dateOrder,
  //           isShowLowComments,
  //           specifiedDate,
  //           isRandomSongs
  //         );
  //       });

  //       set(nextState);
  //     } else {
  //       const sortedSongsNumber = get().sortedSongs.length;
  //       const lastVisibleSongId =
  //         sortedSongsNumber === 0
  //           ? ""
  //           : get().sortedSongs[sortedSongsNumber - 1].id;

  //       const songBatch = (await SongService.fetchSongsByParams(
  //         groups,
  //         assignments,
  //         dateOrder,
  //         isShowLowComments,
  //         specifiedDate,
  //         isRandomSongs,
  //         randomId,
  //         fetchMore,
  //         lastVisibleSongId
  //       ).then(async (batch) => {
  //         const enrichedBatch: any = [];

  //         for (const song of batch) {
  //           await CommentThreadService.fetch(song.id).then((threadDoc) => {
  //             const comments = (threadDoc as CommentsDoc).comments || [];
  //             enrichedBatch.push({ ...song, comments });
  //           });
  //         }
  //         return enrichedBatch;
  //       })) as Song[];

  //       const nextState = produce(get(), (draftState) => {
  //         const freshSortedSongs: any = [];

  //         for (const song of songBatch) {
  //           draftState.songs[song.id] = song;
  //           freshSortedSongs.push(SongService.toRef(song));
  //         }

  //         draftState.sortedSongs = fetchMore
  //           ? [...get().sortedSongs, ...freshSortedSongs]
  //           : freshSortedSongs;
  //       });

  //       set(nextState);
  //     }

  //     set({ status: "idle" });
  //   } catch {
  //     set({ status: "idle" });
  //   }
  // },

  clearSortedSongs: () => {
    const nextState = produce(get(), (draftState) => {
      draftState.sortedSongs = [];
    });

    set(nextState);
  },

  setInitialSortedSongs: () => {
    const nextState = produce(get(), (draftState) => {
      draftState.sortedSongs = get().timeline.slice(0, 10);
    });

    set(nextState);
  },

  shouldFetch: (id) => {
    const directory = get();
    return !directory.all.includes(id) && !directory.empty.includes(id);
  },

  retrieve: (id) => {
    const directory = get();
    return directory.songs[id];
  },

  retrieveByArtist: (id) => {
    const directory = get();
    return directory.artists[id];
  },

  fetch: async (id, toTimeline = false) => {
    const directory = get();
    const exists = directory.songs[id];

    if (exists !== undefined) return;
    set({ status: "fetching" });

    try {
      const song = await SongService.fetch(id);
      // Add Fetched Entries to directory
      // and push to empty state if fetched song was already deleted by author
      const nextState = produce(get(), (draftState) => {
        if (song.id) {
          song.authors.forEach((item) => {
            if (
              draftState.artists[item] &&
              !get().artists[item].some((item) => item.id === song.id)
            ) {
              draftState.artists[item].push(song);
            } else {
              draftState.artists[item] = [song];
            }
          });

          draftState.songs[song.id] = song;

          draftState.all.push(song.id);
          toTimeline && draftState.timeline.unshift(song);
        } else {
          draftState.empty.push(id);
        }

        draftState.status = "idle";
      });
      set(nextState);
    } catch {
      set({ status: "idle" });
    }
  },
}));
