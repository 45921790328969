import React, { useCallback, useEffect, useState } from "react";
import 'firebase/compat/firestore';
import {
  IonButton,
  IonCheckbox,
  IonCol,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonRadio,
  IonRadioGroup,
  IonToggle,
} from "@ionic/react";
import { addOutline, checkmarkOutline } from "ionicons/icons";

import { useConfig } from "../data/store/useConfig";
import { useSongs } from "../data/store/useSongs";
import { useFavorites } from "../data/store/useFavorites";
import { useAssignments } from "../data/store/useAssignments";

import { getCombinedGroups, getIsChecked, trimToTen } from "../util/sugar";
import isEmpty from "lodash.isempty";
import { SpecifiedDate } from "../models/Timeline";
import { SongService } from "../services/SongService";
import { SongRef } from "../models/Song";
import SongList from "./SongList";

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth();
const MONTHS_LIST = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const YEARS_LIST = [currentYear - 1, currentYear];

const SortSongs: React.FC = () => {
  const { favorites } = useFavorites();
  const { groups } = useConfig();
  const allGroups = Object.values(groups)
  const { assignmentsInGroup } = useAssignments();
  const [sortByGroups, setSortByGroups] = useState<boolean>(false);
  const [activeGroup, setActiveGroup] = useState<string[]>([]);
  const [activeAssignment, setActiveAssignment] = useState<string[]>([]);
  const [sortByDate, setSortByDate] = useState<boolean>(false);
  const [formatDateSorting, setFormatDateSorting] = useState<string>("new");
  const [specifiedDate, setSpecifiedDate] = useState<SpecifiedDate>({
    year: currentYear,
    month: currentMonth,
  });
  const [sortByLowComments, setSortByLowComments] = useState<boolean>(false);
  const [isShowFavorites, setIsShowFavorites] = useState<boolean>(false);
  const [isRandomSongs, setIsRandomSongs] = useState<boolean>(false);
  const [songs, setSongs] = useState<SongRef[]>([]);
  const combinedGroups = Object.keys(allGroups);;
  const isOneGroupSelected = activeGroup.length === 1;
  const areAssignmentsEmpty = isEmpty(activeAssignment);
  const availableFavorites = isShowFavorites ? favorites : [];
  const availableGroups = isEmpty(activeGroup) ? combinedGroups : activeGroup;

  const changeGroup = (groupId: string) => () => {
    const isElementInArray = activeGroup.includes(groupId);

    if (isElementInArray) {
      setActiveGroup((prevState) =>
        prevState.filter((item) => item !== groupId)
      );
    } else {
      setActiveGroup((prevState) => {
        const freshState = [...prevState, groupId];

        if (freshState.length > 1) {
          setActiveAssignment([]);
        }

        if (freshState.length >= 10) {
          return trimToTen(freshState);
        }

        return freshState;
      });
    }
  };

  const changeAssignment = (assignmentId: string) => () => {
    const isElementInArray = activeAssignment.includes(assignmentId);

    if (isElementInArray) {
      setActiveAssignment((prevState) =>
        prevState.filter((item) => item !== assignmentId)
      );
    } else {
      setActiveAssignment((prevState) => {
        const freshAssignments = [...prevState, assignmentId];

        if (activeAssignment.length >= 10) {
          return (freshAssignments);
        }

        return freshAssignments;
      });
    }
  };

  const handleSearch = useCallback(() => () => {
    SongService.sortSongs(activeAssignment, availableGroups
    ).then((res) => {
      setSongs(res);
    });
  }, [availableGroups, activeAssignment, formatDateSorting, sortByLowComments, specifiedDate]);

  const handleResetFilter = () => {
    setActiveGroup([]);
    setActiveAssignment([]);
    setSortByDate(false);
    setIsShowFavorites(false);
    setSortByLowComments(false);
    setIsRandomSongs(false);
  };

  useEffect(() => {
    if (!sortByDate) {
      setFormatDateSorting("new");
      setSpecifiedDate({
        year: currentYear,
        month: currentMonth,
      });
    }
    if (!sortByGroups) {
      setActiveGroup([]);
      setActiveAssignment([]);
    }
  }, [sortByDate, sortByGroups]);

  return (
    <div style={{ height: "100%", overflow: "auto" }}>
      <IonCol>
        <IonItem class="ion-no-padding">
          <IonLabel color="primary">All songs</IonLabel>
          <IonCheckbox
            onClick={() => setSortByGroups((prevState) => !prevState)}
            checked={!sortByGroups}
          />
        </IonItem>

        <IonItem class="ion-no-padding">
          <IonLabel color="primary">Sort by Groups or Assignments</IonLabel>
          <IonCheckbox
            onClick={() => setSortByGroups((prevState) => !prevState)}
            checked={sortByGroups}
          />
        </IonItem>

        {sortByGroups &&
          !isEmpty(allGroups) &&
          allGroups.map((item) => {
            const { id, active, title } = item;
            const isChecked = getIsChecked(item.id, activeGroup);
            const groupAssignments = assignmentsInGroup(item.id);
            const titleColor = active ? "dark" : "medium";
            const checkBoxColor =
              areAssignmentsEmpty || activeGroup.length > 1 ? "dark" : "medium";
            const itemTitle = active ? title : `${title} (inactive)`;
            const disabled = !isChecked && activeGroup.length === 10;
            const shouldDisplayAssignments =
              !isEmpty(groupAssignments) && isChecked && isOneGroupSelected;

            return (
              <div key={id}>
                <IonItem>
                  <IonLabel color={titleColor}>{itemTitle}</IonLabel>
                  <IonCheckbox
                    onClick={changeGroup(id)}
                    color={checkBoxColor}
                    checked={isChecked}
                    disabled={disabled}
                  />
                </IonItem>

                {shouldDisplayAssignments && (
                  <IonList class="ion-margin-start">
                    {groupAssignments.map((assignment) => {
                      const isMaxAssigmentNumber =
                        activeAssignment.length === 10;
                      const isAssignmentChecked = getIsChecked(
                        assignment.id,
                        activeAssignment
                      );
                      const assignmentIcon = isAssignmentChecked
                        ? checkmarkOutline
                        : addOutline;

                      return (
                        <IonButton
                          key={assignment.id}
                          size="small"
                          color={isAssignmentChecked ? "success" : "medium"}
                          onClick={changeAssignment(assignment.id)}
                          disabled={
                            !isAssignmentChecked && isMaxAssigmentNumber
                          }
                        >
                          {assignment.title}
                          <IonIcon
                            size="small"
                            slot="start"
                            icon={assignmentIcon}
                          />
                        </IonButton>
                      );
                    })}
                  </IonList>
                )}
              </div>
            );
          })}

        <IonItem class="ion-no-padding">
          <IonLabel color="primary">Sort by date</IonLabel>
          <IonCheckbox
            disabled={isRandomSongs || sortByLowComments}
            onClick={() => {
              setSortByDate((prevState) => !prevState);
            }}
            checked={sortByDate}
          />
        </IonItem>

        {sortByDate && (
          <>
            <IonRadioGroup
              value={formatDateSorting}
              onIonChange={(e) => setFormatDateSorting(e.detail.value)}
            >
              <IonItem>
                <IonLabel>Newer</IonLabel>
                <IonRadio mode="md" color="dark" slot="end" value="new" />
              </IonItem>

              <IonItem>
                <IonLabel>Older</IonLabel>
                <IonRadio mode="md" color="dark" slot="end" value="old" />
              </IonItem>
              <IonItem>
                <IonLabel>Specify date</IonLabel>
                <IonRadio mode="md" color="dark" slot="end" value="specify" />
              </IonItem>
            </IonRadioGroup>

            {formatDateSorting === "specify" && (
              <IonList class="ion-margin-start">
                {YEARS_LIST.map((item) => {
                  const isYearChecked = specifiedDate.year === item;

                  return (
                    <IonButton
                      key={item}
                      size="small"
                      color={isYearChecked ? "success" : "medium"}
                      onClick={() =>
                        setSpecifiedDate((prevState) => {
                          return { ...prevState, year: item };
                        })
                      }
                    >
                      {item}
                      <IonIcon
                        size="small"
                        slot="start"
                        icon={isYearChecked ? checkmarkOutline : addOutline}
                      />
                    </IonButton>
                  );
                })}
                {MONTHS_LIST.map((item, index) => {
                  const isMonthChecked = specifiedDate.month === index;

                  return (
                    <IonButton
                      key={item}
                      size="small"
                      color={isMonthChecked ? "success" : "medium"}
                      onClick={() =>
                        setSpecifiedDate((prevState) => {
                          return { ...prevState, month: index };
                        })
                      }
                    >
                      {item}
                      <IonIcon
                        size="small"
                        slot="start"
                        icon={isMonthChecked ? checkmarkOutline : addOutline}
                      />
                    </IonButton>
                  );
                })}
              </IonList>
            )}
          </>
        )}

        <IonItem class="ion-no-padding">
          <IonLabel color="primary">Show low comments songs</IonLabel>
          <IonToggle
            disabled={isRandomSongs || sortByDate}
            onClick={() => {
              setSortByLowComments((prevState) => !prevState);
            }}
            checked={sortByLowComments}
          />
        </IonItem>

        <IonItem class="ion-no-padding">
          <IonLabel color="primary">Favorite songs</IonLabel>
          <IonToggle
            checked={isShowFavorites}
            onClick={() => {
              setIsShowFavorites((prevState) => !prevState);
            }}
          />
        </IonItem>
        <IonItem class="ion-no-padding">
          <IonLabel color="primary">Random songs</IonLabel>
          <IonToggle
            checked={isRandomSongs}
            onClick={() => {
              setIsRandomSongs((prevState) => !prevState);
              setSortByLowComments(false);
              setSortByDate(false);
            }}
          />
        </IonItem>

        <IonButton
          expand="block"
          color="danger"
          class="ion-margin-top"
          style={{
            borderRadius: 5,
          }}
          onClick={handleResetFilter}
        >
          Reset Fields
        </IonButton>

        <IonButton
          expand="block"
          color="tertiary"
          class="ion-margin-top"
          style={{
            borderRadius: 5,
          }}
          onClick={() => handleSearch()}
        >
          Search Songs
        </IonButton>
      </IonCol>
    </div>
  );
};

export default SortSongs;
