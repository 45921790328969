import firebase from "firebase/compat/app";
import 'firebase/compat/firestore';
import get from "lodash.get";
import isEmpty from "lodash.isempty";
import { CommentThread, FreshCommentNode, ThreadId } from "../models/Comment";
import { FreshNotice } from "../models/Notice";
import { uniq, uuidv4 } from "../util/sugar";
import { NotificationService } from "./NotificationService";
import ObserverService from "./ObserverService";
import { SongService } from "./SongService";
const firestore = firebase.firestore


export class CommentThreadService {
  static stronglyTypeThreadDoc(
    threadDoc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
  ): unknown {
    return { ...threadDoc.data(), id: threadDoc.id };
  }

  static membersNotify = async (
    commentNode: FreshCommentNode,
    threadId: ThreadId
  ) => {
    const noticeId = commentNode.notificationId;

    const setNotification = async (index: number, freshNotice: FreshNotice) => {
      if (!isEmpty(noticeId)) {
        await NotificationService.update(freshNotice, noticeId![index]);
      } else {
        await NotificationService.post(freshNotice).then((notice) => {
          const prevNotificationId = get(commentNode, "notificationId", []);

          commentNode.notificationId = [...prevNotificationId, notice.id];
        });
      }
    };

    await SongService.fetch(threadId).then(async (song) => {
      const authorsList = song.authors.filter(
        (songAuthor) => songAuthor !== commentNode.authorId
      );
      const mentionsList = get(commentNode, "mentions", []).filter(
        (mentionUser) => mentionUser !== commentNode.authorId
      );

      const uniqMembers = uniq([...authorsList, ...mentionsList]);

      for (let i = 0; i < uniqMembers.length; i++) {
        const freshNotice: FreshNotice = {
          from: commentNode.authorId,
          link: "song/" + threadId,
          text: commentNode.text,
          to: uniqMembers[i],
          timestamp: commentNode.timestamp,
        };

        await setNotification(i, freshNotice);
      }
    });
  };

  static transmit = (threadId: ThreadId, commentNode: FreshCommentNode) => {
    return new Promise((resolve, reject) => {
      const db = firestore();
      const serverThread = db.collection("threads").doc(threadId);

      commentNode.timestamp = new Date().getTime().toString();
      commentNode.id = uuidv4();

      serverThread.get().then(async (thread) => {
        await CommentThreadService.membersNotify(commentNode, threadId);
        // await SongService.updateSong(
        //   threadId,
        //   {
        //     commentsNumber: firebase.firestore.FieldValue.increment(1),
        //   } as FreshSong,
        //   false
        // );

        if (thread.exists) {
          const commentUpdate: Record<string, any> = {
            comments: firestore.FieldValue.arrayUnion(commentNode),
          };
          serverThread.update(commentUpdate).then(resolve).catch(reject);
        } else {
          await serverThread.set({ comments: [commentNode] });
        }
      });
    });
  };

  static initialize = () => {
    const db = firestore();
    return db.collection("threads").add({ comments: [] });
  };

  static fetch = (id: ThreadId) => {
    return new Promise((resolve, reject) => {
      const db = firestore();
      db.collection("threads")
        .doc(id)
        .get()
        .then((threadDoc) => {
          resolve(CommentThreadService.stronglyTypeThreadDoc(threadDoc));
        })
        .catch(reject);
    });
  };

  static observe = (
    id: ThreadId,
    onThreadUpdate: (thread: CommentThread) => void
  ) => {
    const db = firestore();
    if (id === "" || undefined) {
      return;
    }
    if (ObserverService.please().exist(id)) {
      return;
    }
    const watcher = db
      .collection("threads")
      .doc(id)
      .onSnapshot((threadDoc) => {
        let thread = threadDoc.data() as CommentThread;
        if (thread === undefined) {
          return;
        }
        thread.id = id;
        onThreadUpdate(thread);
      });
    ObserverService.please().add(id, watcher);
  };

  static stopObserving = (id: ThreadId) => {
    ObserverService.please().remove(id);
  };

  static delete = (threadId: ThreadId, commentId: string) => {
    return new Promise(async (resolve, reject) => {
      const db = firestore();
      db.collection("threads")
        .doc(threadId)
        .get()
        .then((snapshot) => {
          let commentData: firebase.firestore.DocumentData = snapshot.data() || [];
          let comments: FreshCommentNode[] = [];

          // SongService.updateSong(
          //   threadId,
          //   {
          //     commentsNumber: firestore.FieldValue.increment(-1),
          //   } as FreshSong,
          //   false
          // );

          commentData.comments.forEach(async (item: FreshCommentNode) => {
            if (!commentId) {
              return;
            }

            if (item.id !== commentId) {
              comments.push(item);
            } else {
              await NotificationService.removeNotificationsForDeletedMessage(
                item.notificationId!
              );
            }
          });

          commentData.comments = comments;
          db.collection("threads").doc(threadId).update(commentData);
          resolve(true);
        })
        .catch(reject);
    });
  };

  static update = (threadId: ThreadId, text: string, commentId: string) => {
    return new Promise(async (resolve, reject) => {
      const db = firestore();
      db.collection("threads")
        .doc(threadId)
        .get()
        .then((snapshot) => {
          let commentData: any = snapshot.data();
          commentData.comments.forEach(async (item: any, index: any) => {
            if (!commentId) {
              return;
            } else if (item.id === commentId) {
              const updatedComment = commentData.comments[index];
              updatedComment.text = text;

              await CommentThreadService.membersNotify(
                updatedComment,
                threadId
              );
            }
          });
          db.collection("threads").doc(threadId).update(commentData);
          resolve(true);
        })
        .catch(reject);
    });
  };
}
