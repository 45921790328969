import React from "react"


interface SpacerProps {
    height: number
}
export const Spacer: React.FC<SpacerProps> = ({ height }) => {
    return <div style={{ color:"clear!important",height }}> </div>
};

export default Spacer;