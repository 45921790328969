import { IonChip, IonCol, IonGrid, IonRow } from '@ionic/react';
import React, { useEffect } from 'react';
import Content from '../components/Content';
import SongList from '../components/SongList';
import { useConfig } from '../data/store/useConfig';
import { useSongs } from '../data/store/useSongs';
import { useIdParam, usePageTitle } from '../util/sugar';




const TagsPage: React.FC = (props) => {
    const fetchTagged = useSongs(x => x.fetchSongsForHashtag);
    const status = useSongs(x => x.status);
    const tagged = useSongs(x => x.tagged);
    const membership = useConfig(x => x.activeMemberships());
    const id = useIdParam(props);
    usePageTitle(id);
    useEffect(() => {
        id && membership.length > 0 && fetchTagged(membership, id);
        console.log(membership, id);
    }, [fetchTagged, id, membership])

    return <Content className='no-scroll'>
        < IonGrid >
            <IonRow>
                {<IonCol sizeMd="8" sizeLg="6" offsetMd="2" offsetLg="3">
                    {tagged && < SongList
                        status={status}
                        fetchMore={() => { }}
                        songRefs={tagged}
                    />}
                    {tagged && tagged.length === 0 && <>no songs with tag <IonChip>{id}</IonChip> found</>}
                </IonCol>}
            </IonRow>
            <IonRow>
            </IonRow>
        </IonGrid >
    </Content >
};

export default TagsPage;