import create from 'zustand';

export type PlayerState = {
  isPaused: boolean,
  isOver: boolean,
  playNow: () => void,
  pauseNow: () => void,
  songIsOver: () => void,
}


export const usePlayer = create<PlayerState>((set) => ({
  isPaused: true,
  isOver: true,
  playNow: () => {
    set({ isPaused: false, isOver: false });
  },
  pauseNow: () => {
    set({ isPaused: true })
  },
  songIsOver: () => {
    set({ isOver: true })
  },
}));
