import { IonButton, IonCard, IonCardContent, IonCardHeader, IonChip, IonCol, IonIcon, IonInput, IonItem, IonItemDivider, IonLoading, IonProgressBar, IonRow, IonTitle } from '@ionic/react';
import { warningOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import Content from '../components/Content';
import PleaseLoginCard from '../components/PleaseLoginCard';
import { useProfile } from '../data/store/useProfile';
import { InviteService } from '../services/InviteService';
import { useSearchParam } from '../util/sugar';


const Invitation: React.FC = () => {
    let groupId = useSearchParam("session-id") || "public";
    let title = useSearchParam("title") || "public";

    const { authorId, status } = useProfile()
    const [inviteCode, setInviteCode] = useState<string>("");
    const [verificationStatus, setStatus] = useState<"verifying" | "idle">("idle");
    if (status === "unknown") {
        return <Content><IonProgressBar /></Content>
    }
    if (status === "unauthenticated") {
        return <Content><PleaseLoginCard portal={window.location.href} action={"Enter Invite Code"} /></Content>
    }
    const verify = () => {
        setStatus("verifying");
        InviteService.verifyCode(groupId, authorId!, inviteCode).then((response) => {
            console.log(response, "Code Success");
            alert("Congrats! you're now in the session!")
            window.location.href = "/session-songs/" + groupId
        }).catch((response) => {
            console.log(response, "Code Failed");
            alert("Invalid Code")
        }).finally(() => {
            setStatus("idle");
        })
    }
    if (typeof groupId === "undefined") {
        return <Content>
            <IonLoading mode="md" isOpen={verificationStatus === "verifying"} message="Verifying code" />
            <IonCard>
                <IonCardContent>
                    <IonItem color="danger">
                        <IonIcon icon={warningOutline} />
                        Invalid Invitation Link
                    </IonItem>
                </IonCardContent>
            </IonCard>
        </Content>
    }
    return (
        <Content>
            < IonRow >
                <IonCol sizeMd="8" sizeLg="6" offsetMd="2" offsetLg="3">
                    <IonCard>
                        <IonCardHeader color="tertiary" >
                            <IonTitle>Enter your Invite Code!</IonTitle>
                        </IonCardHeader>
                        <IonCardContent>
                            <IonChip>
                                {title}
                            </IonChip>
                            <IonInput placeholder="Enter your code here." onIonChange={(e) => {
                                setInviteCode(e.detail.value!)
                            }} >
                            </IonInput>
                            <IonItemDivider color="clear" />
                            <IonButton fill="outline" expand="full" onClick={verify}>
                                Submit
                            </IonButton>
                        </IonCardContent>
                    </IonCard>
                </IonCol></IonRow >
        </Content >
    );
};

export default Invitation;