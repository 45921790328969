import 'firebase/compat/firestore';
import { ArtistId } from "../models/Artist";
import { Timestamp } from "../models/Timeline";
import { toDateSeconds } from "../util/sugar";


import 'firebase/compat/firestore';
import firebase from "firebase/compat/app";
const firestore = firebase.firestore


export class ActivityService {
    static transmitListeningStatus(id: string, collection: string, artist: string, isActive: boolean) {
        return new Promise<void>((resolve, reject) => {
            var db = firestore();
            if (isActive) {
                db.collection(collection).doc(id).update({ updatedAt: firestore.FieldValue.serverTimestamp(), listening: firestore.FieldValue.arrayUnion(artist) }).then(resolve).catch(reject);
            } else {
                db.collection(collection).doc(id).update({ typing: firestore.FieldValue.arrayRemove(artist) }).then(resolve).catch(reject);
            }
        })
    }

    static transmitTypingStatus = (threadId: string, collection: string, artistId: ArtistId, typing: boolean): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            var db = firestore();
            if (typing) {
                let change: Record<string, firebase.firestore.FieldValue> = {};
                change[artistId] = firestore.FieldValue.serverTimestamp();
                console.log("typing", change);
                db.collection(collection).doc(threadId).set({ typing: change }, { merge: true }).then(resolve).catch(reject);
            } else {
                let change: Record<string, any> = {};
                change[artistId] = {};
                console.log("typing", change);
                db.collection(collection).doc(threadId).set({ typing: change }, { merge: true }).then(resolve).catch(reject);
            }
        })
    };
    static isRecentActity = (timestamp: Timestamp, expiry: number): boolean => {
        const secondsAgo = Date.now() - toDateSeconds(timestamp);
        return (expiry * 1000 - secondsAgo) > 0;
    }
}