import { IonContent, IonItem, IonList, IonSearchbar } from '@ionic/react';
import React from 'react';
import { useSearch } from '../data/store/useSearch';

const SearchPage: React.FC = () => {

  const { search, artists } = useSearch();

  return (
    <IonContent>
      <IonSearchbar debounce={200} onIonChange={(e) => { console.log(e.detail.value); search("artists", e.detail.value!) }}>
      </IonSearchbar>
      <IonList>

        {artists && artists.map((match) => {
          return <IonItem key={match.id}>
            {match.alias}
          </IonItem>
        })}
      </IonList>
    </IonContent>
  );
};

export default SearchPage;