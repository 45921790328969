import 'firebase/compat/analytics';
import firebase from "firebase/compat/app";
import 'firebase/compat/auth';
import { ArtistId } from "../models/Artist";
import { ArtistService } from "./ArtistService";
import ObserverService from "./ObserverService";
import { ProfileService } from "./ProfileService";
(window as any).authChangeWatcher = Function;

const firebaseConfig = {
  apiKey: "AIzaSyA3qlR5HQSnR9LWqmr7AY4ycfaYAGDFRw8",
  authDomain: "songs.schoolofsong.org",
  projectId: "redrocks-sadfam",
  storageBucket: "redrocks-sadfam.appspot.com",
  messagingSenderId: "99636102232",
  appId: "1:99636102232:web:d34b595d6dad639441f62d",
  measurementId: "G-L3B0H5608S",
  //  apiKey: "AIzaSyDbOQKuc8nBWfjNugHIVb4LIVmXPsm95fA",
  //  authDomain: "school-of-song-dev.firebaseapp.com",
  //  projectId: "school-of-song-dev",
  //  storageBucket: "school-of-song-dev.appspot.com",
  //  messagingSenderId: "665574302093",
  //  appId: "1:665574302093:web:f487b8513e1853ac151dc4",
  //  measurementId: "G-WX3DNTNN1D",
};

export const app = firebase.initializeApp(firebaseConfig);

export const firestore=firebase.firestore(app)

// firebase.firestore().enablePersistence({ synchronizeTabs: true })
//   .catch(function (err) {
//     console.log(err);
//   });
export class LoginService {
  static login = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope("https://www.googleapis.com/auth/userinfo.profile");
    provider.addScope("https://www.googleapis.com/auth/userinfo.email");
    firebase.auth().signInWithPopup(provider);
  };
  static logout = () => {
    return firebase.auth().signOut();
  };
  static watchAuthState = (
    onLoggedIn: (artistId: ArtistId, ownerId: string) => void,
    onLoggedOut: () => void
  ) => {
    const isObserving = ObserverService.please().exist("authentication");
    if (isObserving) {
      console.log("authstate observing already");
      return;
    } else {
      const authObserver = firebase.auth().onAuthStateChanged(function (user) {
        if (
          user &&
          user.providerData[0] &&
          user.providerData[0].email === null &&
          user.email !== null
        ) {
          user.providerData[0].email = user.email;
        }

        const providerData = user && { ...user, ...user.providerData[0] };
        if (user && providerData && "email" in providerData) {
          firebase.analytics(app).setUserId(user.uid)
          // User is signed in.
          const artistId = ArtistService.calculateArtistId(
            (providerData as any)["email"]
          );

          const promptForNewAvatarAndLogin = () => {
            const alias = window.prompt(
              "Alias not found, what do we call you?"
            );
            if (alias) {
              let change = { ownerId: user.uid } as any;
              if (alias) change["alias"] = alias;
              ProfileService.changeProfile(change, artistId).then(() => {
                onLoggedIn(artistId, user.uid);
              });
            } else {
              onLoggedIn(artistId, user.uid);
            }
          };

          ArtistService.fetch(artistId)
            .catch(({message})=>{
              console.log(message);
              message&&message==='not-found'&&promptForNewAvatarAndLogin();
            })
            .then((artist) => {
              if (artist && typeof artist.alias === undefined) {
                promptForNewAvatarAndLogin();
              } else {
                // console.log("Successfully logged in as " + (artist as any || { alias: "" }).alias);
                onLoggedIn(artistId, user.uid);
              }
            });
        } else {
          onLoggedOut();
          firebase.analytics(app).setUserId("anon");
        }
      });
      ObserverService.please().add("authentication", authObserver);
    }
  };
}
