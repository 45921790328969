import { Identifiable } from "./Identifiable";


export const defaultConfiguration: Configuration = {
    id: "public",
    allowPublic: "no",
    supportEmail: "blue@schoolofsong.org ",
    title: "School of Song",
    mission: " ",
    rules: "",
    terms: '',
    privacyPolicy: '',
    communityGuidelines: '',
    disclaimer: "",
    version: "6.0.4"
};

export interface Configuration extends Identifiable {
    allowPublic?: string;
    supportEmail: string;
    title: string;
    mission: string,
    transmitNotice?: string;
    rules: string;
    terms?: string,
    privacyPolicy?: string,
    communityGuidelines?: string,
    disclaimer: string;
    version: string,
}
