import React, { useState } from "react";
import {
  IonCard,
  IonCardContent,
  IonCol,
  IonItem,
  IonItemGroup,
  IonItemDivider,
  IonLabel,
  IonCardHeader,
  IonTitle,
  IonRouterLink,
  IonRow,
  IonText,
  IonButton,
} from "@ionic/react";
import Content from "../components/Content";
import PleaseLoginCard from "../components/PleaseLoginCard";

import { useAssignments } from "../data/store/useAssignments";
import { useConfig } from "../data/store/useConfig";
import { useProfile } from "../data/store/useProfile";

import { Group } from "../models/Group";
import { usePageTitle } from "../util/sugar";
import isEmpty from "lodash.isempty";
import "./Mission.scss";

const Groups: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeCompletedIndex, setActiveCompletedIndex] = useState(-1);
  const groups = useConfig((x) => x.activeGroups());
  const allGroups = useConfig((x) => x.groups);
  const membership = useConfig((x) => x.membership);
  const { assignmentsInGroup } = useAssignments();
  const { authenticated } = useProfile();
  const oldGroups = membership
    ? membership.filter((x) => !groups.map((x) => x.id).includes(x))
    : [];

  usePageTitle("Sessions");

  const setExpand = (index: number) => {
    if (index === activeIndex) {
      setActiveIndex(-1);
    } else {
      setActiveIndex(index);
    }
  };

  const setCompletedExpand = (index: number) => {
    if (index === activeCompletedIndex) {
      setActiveCompletedIndex(-1);
    } else {
      setActiveCompletedIndex(index);
    }
  };

  const isAssignmentExist = (group: Group) => {
    return !isEmpty(
      assignmentsInGroup(group.id).filter((item) => item.status !== "hidden")
    );
  };

  const renderAssignment = (group: Group, isExpand: boolean, color: string) => {
    const groupHeight = isExpand ? "100%" : "0";

    return (
      <IonItemGroup
        style={{
          maxHeight: groupHeight,
          overflow: "hidden",
        }}
      >
        {assignmentsInGroup(group.id)
          .filter((x) => x.status === "active" || x.status === "visible")
          .map((x) => (
            <IonItem key={x.id}>
              <IonRouterLink
                routerLink={"/assignment-songs/" + group.id + "/" + x.id}
              >
                <IonText color={color}>
                  <h1>{x.title}</h1>
                </IonText>
              </IonRouterLink>
            </IonItem>
          ))}
        <IonItemDivider color="clear" />
      </IonItemGroup>
    );
  };

  if (!authenticated) {
    return (
      <Content>
        <PleaseLoginCard action={""} />
      </Content>
    );
  }

  return (
    <Content>
      <IonRow>
        <IonCol sizeMd="8" sizeLg="6" offsetMd="2" offsetLg="3">
          <IonCard>
            <IonCardHeader>
              <IonTitle>Current Sessions</IonTitle>
            </IonCardHeader>
            <IonCardContent>
              <IonItemGroup>
                {groups
                  .filter((item) => item.active === true)
                  .map((group, index) => {
                    const isExpandGroup = index === activeIndex;
                    const btnTitle = isExpandGroup
                      ? "Hide Assignments"
                      : "Show Assignments";

                    return (
                      <div key={group.id}>
                        <IonItem lines="full" color="secondary" button>
                          <IonRouterLink
                            routerLink={"/session-songs/" + group.id}
                          >
                            <IonLabel color="dark">
                              <h1>{group.title}</h1>
                            </IonLabel>
                          </IonRouterLink>
                          {isAssignmentExist(group) && (
                            <IonButton
                              slot="end"
                              onClick={() => setExpand(index)}
                            >
                              {btnTitle}
                            </IonButton>
                          )}
                        </IonItem>

                        {renderAssignment(group, isExpandGroup, "primary")}
                      </div>
                    );
                  })}
              </IonItemGroup>
            </IonCardContent>
          </IonCard>

          <IonCard>
            <IonCardContent>
              <IonItemGroup>
                {!isEmpty(oldGroups) && (
                  <IonItem color="medium">Completed Sessions:</IonItem>
                )}

                {oldGroups &&
                  oldGroups.map((groupId, index) => {
                    const group = allGroups[groupId];
                    const isExpandGroup = index === activeCompletedIndex;
                    const btnTitle = isExpandGroup
                      ? "Hide Assignments"
                      : "Show Assignments";

                    return (
                      <div key={groupId}>
                        <IonItem color="clear" lines="full">
                          <IonRouterLink
                            routerLink={"/old-session-songs/" + group.id}
                          >
                            <IonLabel color="dark">
                              <h1>{group.title}</h1>
                            </IonLabel>
                          </IonRouterLink>

                          {isAssignmentExist(group) && (
                            <IonButton
                              slot="end"
                              color="medium"
                              onClick={() => setCompletedExpand(index)}
                            >
                              {btnTitle}
                            </IonButton>
                          )}
                        </IonItem>

                        {renderAssignment(group, isExpandGroup, "medium")}
                      </div>
                    );
                  })}
              </IonItemGroup>
            </IonCardContent>
          </IonCard>
        </IonCol>
      </IonRow>
    </Content>
  );
};

export default Groups;
