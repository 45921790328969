import React from "react";
import {
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonTitle,
  IonItemDivider,
} from "@ionic/react";

interface WarningCardProps {
  text: string;
  additionalText?: string;
}

const WarningCard = ({ text, additionalText }: WarningCardProps) => {
  return (
    <IonCard style={{ background: "white" }}>
      <IonCardHeader color="tertiary">
        <IonTitle>No content to display!</IonTitle>
      </IonCardHeader>
      <IonCardContent>
        <IonItemDivider color="clear" />
        {text}
        {additionalText && (
          <>
            <IonItemDivider color="clear" /> {additionalText}
          </>
        )}
      </IonCardContent>
    </IonCard>
  );
};

export default WarningCard;
