import 'firebase/compat/firestore';
import { Assignment, AssignmentId } from "../models/Assignment";
import { GroupId } from "../models/Group";
import firebase from 'firebase/compat/app';
import { DocumentData, DocumentSnapshot, QueryDocumentSnapshot } from 'firebase/firestore';
const firestore=firebase.firestore

export type SessionAssignments = Record<string, Assignment>;

export class AssignmentService {
  static changeAssignment = (
    ownerId: string,
    groupId: string,
    assignment: Assignment
  ): Promise<SessionAssignments> => {
    return new Promise<SessionAssignments>(() => {
      const db = firestore();
      const assignmentsDbRef = db.collection("assignment").doc(groupId);
      assignmentsDbRef.set({ [assignment.id]: assignment }, { merge: true });
    });
  };
  static deleteAssignment = (
    ownerId: string,
    groupId: string,
    assignmentId: string
  ): Promise<SessionAssignments> => {
    return new Promise<SessionAssignments>(() => {
      const db = firestore();
      const assignmentsDbRef = db.collection("assignment").doc(groupId);

      assignmentsDbRef.update({
        [assignmentId]: firestore.FieldValue.delete(),
      });
    });
  };

  static Listen = (
    groups: GroupId[],
    onAssignmentsChanged: (
      groupId: string,
      assignments: Record<AssignmentId, Assignment>
    ) => void
  ) => {
    const db = firestore();
    console.log("listening to assignments", groups);
    groups.forEach((id) => {
      db.collection("assignment")
        .doc(id)
        .onSnapshot((snap) => {
          onAssignmentsChanged(id, snap.data() as any);
        });
    });
  };

  static Fetch = (
    groups: GroupId[],
    onAssignmentsChanged: (
      groupId: string,
      assignments: Record<AssignmentId, Assignment>
    ) => void
  ) => {
    const db = firestore();
    groups.forEach((id) => {
      db.collection("assignment")
        .doc(id)
        .get().then((snap) => {
          onAssignmentsChanged(id, snap.data() as any);
        });
    });
  };


  static StronglyTypeAssignmentData = (
    assignmentDoc:
      | QueryDocumentSnapshot<DocumentData>
      | DocumentSnapshot<DocumentData>
  ) => {
    return {
      [assignmentDoc.id]: assignmentDoc.data(),
      id: assignmentDoc.id,
    } as any as Record<string, Assignment>;
  };
}
