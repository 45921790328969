import create from "zustand";

interface Toast {
    id: string;
    message: string;
    icon: string;
}

export type ToastState = {
    toasts: Toast[]
    post: (toast: Toast) => void,
    remove: (toast: Toast) => void,
}


export const useToasts = create<ToastState>((set, get) => ({
    toasts: [],
    post: (toast) => {
        const { toasts } = get()
        set({ toasts: [...toasts, toast] })
    },
    remove: (toast) => {
        const { toasts } = get()
        set({ toasts: toasts.filter(x => x.id === toast.id) }
         )
    },

}));