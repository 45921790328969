import 'firebase/compat/firestore';

import { ArtistId } from "../models/Artist";
import { Group, GroupId } from "../models/Group";
import { OwnerId } from "../models/User";

import firebase from "firebase/compat/app";
import 'firebase/compat/firestore';
const firestore = firebase.firestore


export class GroupService {
  static getUserGroups =  (
    userId: string,
    field: "members" | "ownerId",
    onSuccess: (groups: Group[]) => void
  ) => {
    const db = firestore();
    const comparator = field === "ownerId" ? "==" : "array-contains";

    return new Promise((resolve) => {
      db.collection("groups")
        .where(field, comparator, userId)
        .onSnapshot((groupDoc) => {
          const userGroups = [...groupDoc.docs].map((x) =>
            GroupService.StronglyTypeGroupData(x)
          );
          onSuccess(userGroups || []);
          resolve(userGroups);
        });
    });
  };

  static observe = (
    ownerId: OwnerId,
    artistId: ArtistId,
    onGroupAccessGranted: (groups: Group[]) => void
  ) => {
    return Promise.all([GroupService.getUserGroups(artistId, "members", onGroupAccessGranted),
      GroupService.getUserGroups(ownerId, "ownerId", onGroupAccessGranted)])
  };

  static changeGroup = (groupId: GroupId, update: any) => {
    return new Promise<Group>((resolve, reject) => {
      const db = firestore();
      console.trace();
      const groupRef = db.collection("groups").doc(groupId);
      groupRef
        .set(update, { merge: true })
        .then(() => {
          groupRef
            .get()
            .then((groupDoc) => {
              return resolve(groupDoc.data() as Group);
            })
            .catch(reject);
        })
        .catch(reject);
    });
  };

  static allGroups = () => {
    return new Promise<Group[]>((resolve, reject) => {
      const db = firestore();
      const groupsRef = db.collection("groups");
      groupsRef.get()
        .then((items) => {
          const myGroups=items.docs.map(x => ({ id: x.id, ...x.data() }) as any as Group) as Group[];
          console.log(myGroups)
          return resolve(myGroups);
        })
        .catch(reject);
    });
  };
  static fetchGroup = (groupId: GroupId) => {
    return new Promise<Group>((resolve, reject) => {
      const db = firestore();
      const groupsRef = db.collection("groups").doc(groupId);
      groupsRef.get()
        .then((doc) => {
          console.log("FETCHED", doc.data());
          return resolve(({ ...doc.data(), id: doc.id } as Group));
        })
        .catch(reject);
    });
  };


  static deleteGroup = (groupId: GroupId) => {
    return new Promise<Group>((resolve, reject) => {
      const db = firestore();
      const groupRef = db.collection("groups").doc(groupId);
      groupRef
        .delete()
        .then(() => {
          resolve(undefined as any);
        })
        .catch(reject);
    });
  };

  static StronglyTypeGroupData = (
    groupDoc:
      | firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
      | firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
  ) => {
    return { ...groupDoc.data(), id: groupDoc.id } as Group;
  };
}
