import create from "zustand";
import { Assignment } from "../../models/Assignment";
import { GroupId } from "../../models/Group";
import { OwnerId } from "../../models/User";
import { AssignmentService } from "../../services/AssignmentService";

export type AssignmentsState = {
  assignments: Record<string, Record<string, Assignment>>;
  setAssignment: (
    ownerId: OwnerId,
    groupID: GroupId,
    assignment: Assignment
  ) => void;
  deleteAssignment: (
    ownerId: OwnerId,
    groupId: GroupId,
    assignmentId: string
  ) => void;
  activeAssignments: string[];
  allAssignments: string[];
  assignmentsInGroup: (group: string) => Assignment[];
  fetchAssignmentsInGroup: (group: string) => void;
  observe: (groups: GroupId[]) => void;
  observeGroup: (group: GroupId) => void;
};

export const useAssignments = create<AssignmentsState>((set, store) => ({
  activeAssignments: [],
  allAssignments: [],
  assignments: {},
  observe: async (groups) => {
    AssignmentService.Listen(groups, (group, assignments) => {
      set({
        assignments: { ...store().assignments, [group]: assignments },
      });
    });
  },
  observeGroup: async (group) => {
    AssignmentService.Listen([group], (group, assignments) => {
      set({
        assignments: { ...store().assignments, [group]: assignments },
      });
    });
  },
  assignmentsInGroup: (group) => {
    const assignmentItems = Object.values(store().assignments[group] || []);
    const hasOrder = assignmentItems.every((a) =>
      Object.keys(a).includes("order")
    );
    return assignmentItems.sort((a, b) => {
      return hasOrder ? a.order - b.order : a.title.length - b.title.length;
    });
  },
  fetchAssignmentsInGroup:(group:any)=>{
      AssignmentService.Fetch([group], (group, assignments) => {
        set({
          assignments: { ...store().assignments, [group]: assignments },
        });
      })
  },
  setAssignment: (ownerId, groupId: string, assignment) => {
    console.log({ownerId, groupId, assignment});
    AssignmentService.changeAssignment(ownerId, groupId, assignment);
  },
  deleteAssignment: (
    ownerId: string,
    groupId: string,
    assignmentId: string
  ) => {
    AssignmentService.deleteAssignment(ownerId, groupId, assignmentId);
  },
}));
