import produce from "immer";
import create from "zustand";
import { AlbumId, Album } from "../../models/Albums";
import { SongId, MediaReference } from "../../models/Song";
import { AlbumsService } from "../../services/AlbumsService";
import { toTimestamp, uuidv4 } from "../../util/sugar";
import { songHookStatus } from "./useSongs";
import { OwnerId } from "../../models/User";
import groupBy from "lodash.groupby";

export type AlbumsState = {
  status: songHookStatus;
  albumId: AlbumId | undefined;
  all: Album[];
  albums: Record<AlbumId, Album>;
  albumsByUser: Record<OwnerId, Album[]>;
  retrieve: (id: AlbumId) => Album | undefined;
  fetchAll: () => Promise<Album[]>;
  fetch: (id: AlbumId) => void;
  updateAlbum: (album: Album) => void;
  transmit: (
    owner: string,
    name: string,
    description: string,
    art: MediaReference,
    isPrivate: boolean,
    authorId: string
  ) => Promise<AlbumId>;
  delete: (id: AlbumId) => void;
  isInAlbum: (id: AlbumId, songId: SongId) => boolean;
  addToAlbum: (id: AlbumId, songId: SongId) => Promise<Album>;
  removeFromAlbum: (id: AlbumId, songId: SongId) => Promise<Album>;
};

export const useAlbums = create<AlbumsState>((set, get) => ({
  status: "idle",
  albumId: undefined,
  all: [],
  albumsByUser: {},
  albums: {},
  fetchAll: async () => {
    set({ status: "fetching" });
    return new Promise(async (resolve) => {
      let albumsData = await AlbumsService.fetchAll();

      let datedLists = [];
      let undatedLists = [];

      for (const album of albumsData) {
        album.updatedAt ? datedLists.push(album) : undatedLists.push(album);
      }

      datedLists.sort((a, b) => b.updatedAt - a.updatedAt);
      undatedLists.sort((a, b) => a.title.localeCompare(b.title));

      const allAlbums = [...datedLists, ...undatedLists];
      const albumsByUserId = groupBy(allAlbums, "ownerId");

      const newState = produce(get(), (draft) => {
        for (const album of allAlbums) {
          draft.albums[album.id] = album;
        }
        draft.all = allAlbums;
        draft.albumsByUser = albumsByUserId;
        draft.status = "idle";
      });
      set(newState);
      resolve(allAlbums);
    });
  },

  fetch: async (id) => {
    set({ status: "fetching" });
    const album = await AlbumsService.fetch(id);
    const newState = produce(get(), (draft) => {
      draft.albums[album.id] = album;
      draft.status = "idle";
    });
    set(newState);
  },

  retrieve: (id) => {
    return get().albums[id];
  },

  transmit: (ownerId, title, description, art, isPrivate, authorId) => {
    set({ status: "fetching" });
    const album: Album = {
      authorId,
      id: uuidv4(),
      ownerId,
      songs: [],
      title,
      description,
      art,
      isPrivate,
      updatedAt: Date.now(),
    };

    return new Promise((resolve, reject) => {
      AlbumsService.transmit(album)
        .then(() => {
          set({ albumId: album.id });
          resolve(album.id);
        })
        .catch(reject);

      set({ status: "idle" });
    });
  },

  delete: (id) => {
    set({ status: "deleting" });

    return new Promise((resolve, reject) => {
      AlbumsService.delete(id)
        .then(() => {
          const newState = produce(get(), (draft) => {
            const filteredLists = get().all.filter((x) => x.id !== id);
            for (const album of filteredLists) {
              draft.albums[album.id] = album;
            }
            draft.all = filteredLists;
            delete draft.albums[id];
            draft.status = "idle";
          });
          set(newState);
        })
        .then(resolve)
        .catch(reject);
    });
  },

  isInAlbum: (id, songId) => {
    return get()
      .albums[id].songs.map((x) => x.id)
      .includes(songId);
  },

  addToAlbum: (id, songId) => {
    const album = get().albums[id];
    const newSong = {
      id: songId,
      updatedAt: toTimestamp(new Date().getSeconds()),
    };
    const albumUpdate = { ...album, songs: [...album.songs, newSong] };

    return new Promise((resolve, reject) => {
      AlbumsService.update(albumUpdate)
        .then((newAlbum) => {
          const newState = produce(get(), (draftState) => {
            draftState.albums[id] = newAlbum;
            draftState.status = "idle";
          });
          set(newState);
          resolve(newAlbum);
        })
        .catch(reject);
    });
  },

  removeFromAlbum: (id, songId) => {
    const album = get().albums[id];
    const albumUpdate = {
      ...album,
      songs: [...album.songs.filter((item) => item.id !== songId)],
    };

    return new Promise((resolve, reject) => {
      AlbumsService.update(albumUpdate)
        .then((newAlbum) => {
          const newState = produce(get(), (draftState) => {
            draftState.albums[id] = newAlbum;
            draftState.status = "idle";
          });
          set(newState);
          resolve(album);
        })
        .catch(reject);
    });
  },
  updateAlbum: async (freshAlbum: Album) => {
    set({ status: "fetching" });

    return new Promise((resolve, reject) => {
      AlbumsService.update(freshAlbum)
        .then(() => {
          const nextState = produce(get(), (draftState) => {
            draftState.albums[freshAlbum.id] = {
              ...freshAlbum,
            };
          });

          set(nextState);
          resolve();
        })
        .catch(reject)
        .finally(() => set({ status: "idle" }));
    });
  },
}));
