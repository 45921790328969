/* eslint-disable react-hooks/rules-of-hooks */
import { RefObject } from 'react';
import { Song } from '../models/Song';
import { app } from "../services/LoginService"
import firebase from 'firebase/compat/app';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare const window: any;

const AudioContext = window.AudioContext || window.webkitAudioContext;

export default class MediaService {
  private static instance: MediaService;
  private context!: AudioContext;
  private audioElement!: HTMLAudioElement;
  private mediaSourceNode!: MediaElementAudioSourceNode;
  private analyzerNode!: AnalyserNode;
  private initialized: boolean = false;
  private frequencyData: Uint8Array[] = [];
  private waveFormData: Uint8Array[] = [];
  private frequencyBufferIndex: number = 0;
  private waveFormBufferIndex: number = 0;
  public progress = 0;
  public buffer = 0;
  public isPlaying = false;
  public nowPlaying: string = '';
  private constructor() { }

  public static please(): MediaService {
    if (!MediaService.instance) {
      MediaService.instance = new MediaService();
    }
    return MediaService.instance;
  }

  public setPlayerRef(playerRef: RefObject<HTMLAudioElement>) {
    if (playerRef.current) {
      this.audioElement = playerRef.current;
    }
    if (window.AudioContext) {
      this.context = new AudioContext();
    }
  }

  public seek(percentage: number) {
    this.audioElement.currentTime = percentage * this.audioElement.duration
  }

  public updateBuffer() {
    const audioElement = this.audioElement;
    const time = audioElement.currentTime;
    const duration = audioElement.duration;
    this.progress = time / duration;
    if (duration > 0) {
      try {
        const bufferedEnd = audioElement.buffered.end(audioElement.buffered.length - 1);
        this.buffer = bufferedEnd / duration;
      } catch {
        this.buffer = 0;
      }
    }
  }

  public pause() {
    this.audioElement.pause();
  }

  public play() {
    this.audioElement.play();
  }

  public getContext() {
    return this.context;
  }

  public getAudioElement() {
    return this.audioElement;
  }

  public getMediaSourceNode() {
    return this.mediaSourceNode;
  }

  public getAnalyzerNode() {
    return this.analyzerNode;
  }

  public getDestinationNode() {
    return this.getDestinationNode;
  }

  public async setSong(song: Song) {
    firebase.analytics(app).logEvent("play_song", { id: song.id });
    if (this.nowPlaying === song.id) {
      return;
    } else {
      this.nowPlaying = song.id;
    }
    if (this.audioElement) {
      this.audioElement.volume = 0;
      this.audioElement.src = song.media.src;
      this.audioElement.volume = 1;
    }
  }

  public initializeAnalysis() {
    if (!this.initialized) {
      this.mediaSourceNode = this.context.createMediaElementSource(this.audioElement);
      this.analyzerNode = this.context.createAnalyser();
      this.mediaSourceNode.connect(this.analyzerNode);
      this.analyzerNode.connect(this.context.destination);
      this.initialized = true;
      this.frequencyData.push(new Uint8Array(this.analyzerNode.frequencyBinCount));
      this.waveFormData.push(new Uint8Array(this.analyzerNode.fftSize));
      this.context.resume();
    }
    return this.initialized;
  }

  public getWaveFormBufferSize() {
    return this.waveFormData.length * this.analyzerNode.fftSize;
  }

  public getLatestFrequencyData() {
    this.analyzerNode.getByteFrequencyData(this.frequencyData[this.frequencyBufferIndex]);
    this.frequencyBufferIndex = (this.frequencyBufferIndex + 1) % this.frequencyData.length;
  }

  public getLatestWaveFormData() {
    this.analyzerNode.getByteTimeDomainData(this.waveFormData[this.waveFormBufferIndex]);
    this.waveFormBufferIndex = (this.waveFormBufferIndex + 1) % this.waveFormData.length;
  }

  public getFrequencyBuffers() {
    return this.frequencyData;
  }

  public getWaveBuffers() {
    return this.waveFormData;
  }

  public getActiveFrequencyBufferIndex() {
    return this.frequencyBufferIndex;
  }

  public getActiveWaveBufferIndex() {
    return this.waveFormBufferIndex;
  }

  public getCircularWaveBufferSize() {
    return this.analyzerNode.fftSize * this.waveFormData.length;
  }

  public beReady() {
    return this.initialized;
  }
}
