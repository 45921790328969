import { IonChip, IonIcon } from "@ionic/react";
import React from "react";
import { useArtists } from "../data/store/useArtists";
import { ArtistService } from "../services/ArtistService";
import { closeOutline } from "ionicons/icons";

interface inviteChipParams {
  email: string;
  showDeleteIcon?: boolean;
  onClick?: () => void;
}

export const GroupInviteChip: React.FC<inviteChipParams> = ({
  showDeleteIcon = false,
  email,
  onClick = () => {},
}) => {
  const authorId = ArtistService.calculateArtistId(email);
  const { all } = useArtists();
  const artistExists = all.includes(authorId);

  return (
    <IonChip onClick={onClick} color={artistExists ? "favorite" : "warning"}>
      {showDeleteIcon && (
        <IonIcon color="dark" icon={closeOutline} style={{ margin: 0 }} />
      )}
      {email}
    </IonChip>
  );
};

export default GroupInviteChip;
