import {
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonCardTitle,
  IonInput,
  // IonTextarea,
  IonItem,
  IonLabel,
  // IonText
} from '@ionic/react';
import React from 'react';
// import Spacer from '../components/Spacer';
import { useConfig } from '../data/store/useConfig';
import { defaultConfiguration } from '../models/Configuration';


const Config: React.FC = () => {
  const { config, changeConfig } = useConfig();
  const {
    mission,
    // terms,
    // privacyPolicy,
    // communityGuidelines,
    transmitNotice,
    supportEmail,
    id,
    title,
    rules
  } = config || defaultConfiguration;
  const setData = (property: string, value: any) => {
    changeConfig(id, property, value);
  };

  return (
    <IonCard>
      <IonCardHeader color="favorite">
        <IonCardTitle>
          Configuration for ({id})
        </IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <IonItem>
          <IonLabel position="floating" color="favorite">
            Support Email
          </IonLabel>
          <IonInput
            value={supportEmail}
            onIonChange={(e) => {setData("supportEmail", e.detail.value! || "")}}
          />
        </IonItem>
        <IonItem>
          <IonLabel color="favorite" position="floating">
            Title
          </IonLabel>
          <IonInput
            value={title}
            onIonChange={(e) => {setData("title", e.detail.value! || "")}}
          />
        </IonItem>
        <IonItem>
          <IonLabel color="favorite" position="floating">
            Mission
          </IonLabel>
          <IonInput
            value={mission}
            onIonChange={(e) => {setData("mission", e.detail.value! || "")}}
          />
        </IonItem>
        <IonItem>
          <IonLabel position="floating" color="favorite">
            Transmission Notice
          </IonLabel>
          <IonInput
            value={transmitNotice}
            onIonChange={(e) => {setData("transmitNotice", e.detail.value! || "")}}
          />
        </IonItem>
        <IonItem>
          <IonLabel position="floating" color="favorite">
            Rules
          </IonLabel>
          <IonInput
            value={rules}
            onIonChange={(e) => {setData("rules", e.detail.value! || "")}}
          />
        </IonItem>

       {/* <Spacer height={60} />
        <IonItem color="medium">
          <IonText>For the Terms/Policy/Guidelines page you can use the Markdown, HTML, or mixed syntax.</IonText>
        </IonItem>

        <IonItem>
          <IonLabel color="favorite" position="floating">
            Terms
          </IonLabel>
          <IonTextarea
            value={terms}
            rows={8}
            onIonChange={e => {setData('terms', e.detail.value! || '')}}
          />
        </IonItem>
        <IonItem>
          <IonLabel color="favorite" position="floating">
            Privacy Policy
          </IonLabel>
          <IonTextarea
            value={privacyPolicy}
            rows={8}
            onIonChange={e => {setData('privacyPolicy', e.detail.value! || '')}}
          />
        </IonItem>
        <IonItem>
          <IonLabel color="favorite" position="floating">
            Community Guidelines
          </IonLabel>
          <IonTextarea
            value={communityGuidelines}
            rows={8}
            onIonChange={e => {setData('communityGuidelines', e.detail.value! || '')}}
          />
        </IonItem>*/}

        {/* <IonItem>
            <IonButtons slot="start">
                <IonLabel position="floating">
                    Allow Public
            </IonLabel>

            </IonButtons>
            <IonButtons slot="end">
                <IonToggle onIonChange={(e) => {
                    setData("allowPublic", e.detail.checked ? "yes" : "no");
                }} checked={allowPublic === "yes"}>
                </IonToggle>
            </IonButtons>
        </IonItem> */}
      </IonCardContent>
    </IonCard>
  );
};

export default Config;
