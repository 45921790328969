import { IonCard, IonCardContent, IonCol, IonDatetime, IonGrid, IonItem, IonItemDivider, IonLabel, IonRow } from "@ionic/react";
import React from "react";
import { useHistory } from "react-router";
import { usePageTitle } from "../util/sugar";
import Content from "./Content";
const Archives: React.FC = () => {
    usePageTitle("Archives");
    const { push } = useHistory();
    return <Content classname="chill">
        <IonGrid>
            <IonRow>
                <IonCol sizeMd="8" sizeLg="6" offsetMd="2" offsetLg="3">

                    <IonCard className="inner-chill">
                        <IonCardContent>
                            Welcome to the archives.
                            <IonItemDivider color="clear" />
                            <IonItem>
                                <IonLabel >
                                    Select Date
                                </IonLabel>
                                <IonDatetime pickerOptions={{
                                }} max={new Date().toISOString().split("T")[0]} placeholder="Select Date" color="primary" onIonChange={(e) => {
                                    push("/archive/" + e.detail.value!.split("T")[0]);
                                }} />

                            </IonItem>
                        </IonCardContent>
                    </IonCard>
                </IonCol>
            </IonRow>
        </IonGrid>
    </Content >
}
export default Archives