import React from "react";
import { IonAlert } from "@ionic/react";

interface ConfirmationAlert {
  message: string;
  onConfirm: () => void;
  onDismiss: () => void;
  isAlertOpen: boolean;
}

export const ConfirmationAlert: React.FC<ConfirmationAlert> = ({
  message,
  onConfirm,
  onDismiss,
  isAlertOpen,
}) => {
  return (
    <IonAlert
      isOpen={isAlertOpen}
      onDidDismiss={onDismiss}
      header={"Confirm"}
      message={message}
      buttons={[
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
        },
        {
          text: "Delete",
          handler: () => {
            onConfirm();
          },
        },
      ]}
    />
  );
};

export default ConfirmationAlert;
