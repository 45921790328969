import {
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonContent,
  IonHeader,
  IonItem,
  IonPage,
  IonToolbar,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { firestore } from "../services/LoginService";
import { getApp } from "firebase/app";
import React from "react";
import { uniq } from "../util/sugar";
import { Assignment } from "../models/Assignment";
import { Group } from "../models/Group";
import {
  Query,
  getAggregateFromServer,
  AggregateSpec,
  count,
  collection,
  query,
  where,
  getFirestore,
  getCountFromServer,
} from "firebase/firestore";
import { useParams } from "react-router";

export const CountAssignment: React.FC<{ id: string; group: string }> = ({
  id,
}) => {
  const [count, setCount] = useState<number>(0);
  const a: AggregateSpec = {};
  useEffect(() => {
    const db = getFirestore(getApp());
    const coll = collection(db, "songs");
    const q = query(coll, where("assignment", "==", id));
    getCountFromServer(q).then((snapshot) => {
      setCount(snapshot.data()?.count as any);
    });
  }, [id]);
  return <>{count}</>;
};

export const Stats: React.FC = () => {
  const [group, setGroup] = useState<Group | any>(null);
  const { id: groupId } = useParams<{ id: string }>();
  const [assignments, setAssignments] = useState<Record<string, Assignment>>(
    {}
  );
  useEffect(() => {
    firestore
      .collection("groups")
      .doc(groupId)
      .get()
      .then((snapshot) => {
        setGroup(snapshot.data());
      });
    firestore
      .collection("assignment")
      .doc("34025eb8-b1e1-4da4-a7de-8109cc409924")
      .get()
      .then((snapshot) => {
        setAssignments(snapshot.data() as any);
      });
  }, []);
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar />
      </IonHeader>
      <IonContent>
        <IonCard>
          <IonCardHeader>{uniq(group?.members).length}</IonCardHeader>

          <IonCardContent>
            {uniq(Object.values(assignments)).map((assignment) => (
              <IonItem>
                {assignment.title}

                <IonButtons slot="end">
                  <CountAssignment id={assignment.id} group={groupId} />
                </IonButtons>
              </IonItem>
            ))}
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};
