import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonInput,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonListHeader,
  IonSearchbar,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";

import { useConfig } from "../data/store/useConfig";
import { useProfile } from "../data/store/useProfile";

import { Group } from "../models/Group";
import { uuidv4 } from "../util/sugar";

import AsyncArtistButton from "./AsyncArtistButton";
import GroupForm from "./GroupForm";
import GroupInviteChip from "./GroupInviteChip";
import { useAssignments } from "../data/store/useAssignments";

const GroupItem: React.FC<{ group: Group; onComplete: () => void }> = ({
  group,
  onComplete,
}) => {
  const { id, title, description, invites, members } = group;
  const [editing, setEditing] = useState(false);
  const [showUsers, setShowUsers] = useState(false);
  return editing ? (
    <IonCard>
      <IonCardContent>
        <GroupForm
          onComplete={() => {
            setEditing(false);
            onComplete();
          }}
          groupId={id}
        />
      </IonCardContent>
    </IonCard>
  ) : (
    <IonCard>
      <IonCardHeader color="primary">
        <IonCardTitle>{title}</IonCardTitle>
        <IonCardSubtitle>{description}</IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent>
        <IonButton
          fill="solid"
          className="ion-margin-top"
          onClick={() => setShowUsers((x) => !x)}
        >
          {!showUsers ? "Show  Invites / Members" : "Hide Invites / Members"}
        </IonButton>

        <IonList>
          {showUsers && <IonListHeader>Invites:</IonListHeader>}
          {showUsers &&
            invites &&
            invites.map((invite) => <GroupInviteChip email={invite} />)}
          {showUsers && <IonListHeader>Members:</IonListHeader>}
          {showUsers &&
            members &&
            members.map((memberId) => (
              <AsyncArtistButton variant={"alias"} id={memberId} />
            ))}
        </IonList>

        <IonItemDivider color="clear" />

        <IonButton
          color="tertiary"
          expand="full"
          onClick={() => setEditing(true)}
        >
          Edit
        </IonButton>
      </IonCardContent>
    </IonCard>
  );
};

const GroupAdmin: React.FC = () => {
  const { createGroup, fetchAllGroups } = useConfig();
  const { ownerId, authorId } = useProfile();
  const [newGroupTitle, setNewGroupTitle] = useState("");
  const [addingNewGroup, setIsAddingNewGroup] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  const [listState, setListState] = useState<string>("active");
  const [groups, setGroups] = useState<Group[]>([]);
  const [query, setQuery] = useState<string>("");
  const { observeGroup } = useAssignments();

  useEffect(() => {
    if (addingNewGroup) {
      return;
    }
    fetchAllGroups(ownerId).then((groupsFetched) => {
      setGroups(groupsFetched);
      setUpdateData(false);
    });
  }, [ownerId, fetchAllGroups, addingNewGroup, updateData]);
  const groupsItems = Object.values(groups);

  const toggleDisplay = (val: any) => {
    setListState(val.detail.value);
  };

  return (
    <>
      <IonCard>
        <IonCardHeader color="favorite">
          <IonCardTitle>Groups Admin</IonCardTitle>
        </IonCardHeader>
      </IonCard>

      <IonCard>
        <IonSegment value={listState} onIonChange={toggleDisplay}>
          <IonSegmentButton value="active">Active groups</IonSegmentButton>
          <IonSegmentButton value="inactive">Inactive groups</IonSegmentButton>
        </IonSegment>
      </IonCard>
      <IonItem>
        <IonSearchbar
          value={query}
          onIonChange={(e) => {
            setQuery(e.detail.value || "");
          }}
        ></IonSearchbar>

        <IonButtons slot="end">
          {!addingNewGroup && (
            <IonButton
              expand="full"
              fill="solid"
              className="ion-margin-start"
              color="warning"
              onClick={() => setIsAddingNewGroup(true)}
            >
              Add a Group
            </IonButton>
          )}
        </IonButtons>
      </IonItem>
      {listState === "active"
        ? groupsItems
            .filter((i) => i.active)
            .filter((i) => i.title.toLowerCase().includes(query.toLowerCase()))
            .map(
              (group) =>
                !addingNewGroup && (
                  <GroupItem
                    onComplete={() => {
                      setUpdateData(true);
                    }}
                    key={group.id}
                    group={group}
                  />
                )
            )
        : groupsItems
            .filter((i) => !i.active)
            .filter((i) => i.title.toLowerCase().includes(query.toLowerCase()))
            .map(
              (group) =>
                !addingNewGroup && (
                  <GroupItem
                    onComplete={() => {
                      setUpdateData(true);
                    }}
                    key={group.id}
                    group={group}
                  />
                )
            )}

      {!addingNewGroup ? (
        <IonButton
          className="ion-margin-start"
          color="warning"
          onClick={() => setIsAddingNewGroup(true)}
        >
          Add a Group
        </IonButton>
      ) : (
        <>
          <IonItem>
            <IonLabel position="stacked">Group Name</IonLabel>
            <IonInput
              placeholder="Enter Group Name"
              onIonChange={(e) => setNewGroupTitle(e.detail.value!)}
            />
          </IonItem>
          <IonItem lines="none">
            {authorId && (
              <IonButton
                size="default"
                onClick={() => {
                  const newUuid = uuidv4();
                  const newGroup: Group = {
                    authorId: authorId || "",
                    id: newUuid,
                    title: newGroupTitle,
                    description: "",
                    members: [authorId || ""],
                    active: true,
                    invites: [],
                    path: "",
                    ownerId,
                  };
                  ownerId &&
                    createGroup(ownerId, authorId, newUuid, newGroupTitle);
                  setNewGroupTitle("");
                  setQuery(newGroupTitle);
                  setIsAddingNewGroup(false);
                  setGroups([...groups, newGroup]);
                  observeGroup(newUuid);
                }}
              >
                Save
              </IonButton>
            )}
          </IonItem>
        </>
      )}
    </>
  );
};

export default GroupAdmin;
