import React, { useEffect, useLayoutEffect, useState } from "react";
import { useParams } from "react-router";
import {
  IonButton,
  IonCard,
  IonCol,
  IonGrid,
  IonItem,
  IonRow,
  IonSpinner,
} from "@ionic/react";
import { useAssignments } from "../data/store/useAssignments";
import { useConfig } from "../data/store/useConfig";
import { useSongs } from "../data/store/useSongs";

import Content from "./Content";
import SongList from "./SongList";
import { usePageTitle } from "../util/sugar";
import isEmpty from "lodash.isempty";
import { useProfile } from "../data/store/useProfile";
import CompactSongList from "./CompactSongList";

export const AssignmentSongs: React.FC = () => {
  const { assignmentsInGroup } = useAssignments();
  const {compact}=useProfile();
  const { id, assignmentId } = useParams<{id:string,assignmentId:string}>();
  const { assignments, status, songsTotal, songs, fetchSongsForAssignment } =
    useSongs();
  const assignmentSongs = assignments[assignmentId];
  const groups = useConfig((x) => x.activeMemberships());
  const isAlumni = useConfig((x) => x.isAlumni());
  // TODO: do we need to use fetched state?
  const [fetched, setFetched] = useState<string>();
  const [page, setPage] = useState<number>(1);
  const [songsList, setSongsList] = useState(assignmentSongs);

  const assignmentsIngGroup = id && assignmentsInGroup(id);
  const assignment =
    assignmentsIngGroup &&
    assignmentsIngGroup.find((x) => x.id === assignmentId);

  const refreshPage = () => {
    window.location.reload();
  };

  const noMoreSongs = Object.keys(songs).length === songsTotal;

  usePageTitle(assignment ? assignment.title : "Loading");

  useLayoutEffect(() => {
    if (!assignmentId) {
      refreshPage();
    }
  }, [assignmentId]);

  useEffect(() => {
    setSongsList(assignmentSongs);
  }, [assignmentSongs]);

  useEffect(() => {
    if (status === "fetching") return;

    const fetchNext = assignmentId + "-" + page;

    if (
      typeof id !== "undefined" &&
      typeof assignmentId !== "undefined" &&
      fetched !== fetchNext
    ) {
      setFetched(fetchNext);
      fetchSongsForAssignment(assignmentId);
    }
    // eslint-disable-next-line
  }, [
    id,
    groups,
    fetchSongsForAssignment,
    status,
    fetched,
    isAlumni,
    assignmentId,
  ]);

  return (
    <Content classname="chill">
      <IonGrid>
        <IonRow>
          {status === "fetching" && (
            <IonCol sizeMd="8" sizeLg="6" offsetMd="2" offsetLg="3">
              <IonRow className="ion-justify-content-center">
                <IonSpinner name="dots" />
              </IonRow>
            </IonCol>
          )}

          {status === "idle" &&
            assignmentSongs &&
            assignmentSongs.length === 0 && (
              <IonCol sizeMd="8" sizeLg="6" offsetMd="2" offsetLg="3">
                <IonCard>
                  <IonItem>
                    No songs found for this assignment ~
                    <IonButton onClick={refreshPage}>Refresh</IonButton>
                  </IonItem>
                </IonCard>
              </IonCol>
            )}
        </IonRow>

        <IonRow>
          {!isEmpty(songsList) &&<IonCol sizeMd="8" sizeLg="6" offsetMd="2" offsetLg="3">
            {<CompactSongList status={status}
              songRefs={songsList}
              fetchMore={()=>{}}
              hideFetchButton={noMoreSongs}/>}            
          </IonCol>}
        </IonRow>
      </IonGrid>
    </Content>
  );
};
