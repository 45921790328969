import produce from "immer";
import create from "zustand";
import { SongId } from "../../models/Song";

export type PlaylistState = {
  title: string | undefined;
  id: string | undefined;
  saved: string | undefined;
  songs: SongId[];
  nowPlayingId?: SongId;
  nextSong: () => SongId;
  getNextSongIfAvailable: () => SongId;
  previousSong: () => SongId;
  queueSong: (id: SongId) => void;
  removeSong: (id: SongId) => void;
  setNowPlaying: (id: SongId) => void;
};

export const usePlaylist = create<PlaylistState>((set, get) => ({
  title: undefined,
  saved: undefined,
  id: undefined,
  songs: [],
  nowPlayingId: "",
  setNowPlaying: (id) => {
    set({ nowPlayingId: id });
  },
  queueSong: (id) => {
    const playlistSongs = get().songs;
    const playlistWithNewSong = produce(playlistSongs, (songs) => {
      if (!songs.includes(id)) songs.push(id);
    });
    set({ songs: playlistWithNewSong });
  },
  removeSong: (id) => {
    const playlistSongs = get().songs;
    set({ songs: [...playlistSongs.filter((x) => x !== id)] });
  },
  previousSong: () => {
    const playlist = get();
    const nowPlayingIndex = playlist.songs.findIndex(
      (id) => id === playlist.nowPlayingId
    );
    let prevPlayingIndex = nowPlayingIndex - 1;
    if (prevPlayingIndex === -1) {
      prevPlayingIndex += playlist.songs.length;
    }
    const nowPlaying = playlist.songs[prevPlayingIndex];
    set({ nowPlayingId: nowPlaying });
    return nowPlaying;
  },
  nextSong: () => {
    const playlist = get();
    const nowPlayingIndex = playlist.songs.findIndex(
      (id) => id === playlist.nowPlayingId
    );
    let nextPlayingIndex = (nowPlayingIndex + 1) % playlist.songs.length;
    const nowPlaying = playlist.songs[nextPlayingIndex];
    set({ nowPlayingId: nowPlaying });
    return nowPlaying;
  },
  getNextSongIfAvailable: () => {
    const playlist = get();
    const nowPlayingIndex = playlist.songs.findIndex(
      (id) => id === playlist.nowPlayingId
    );

    if (playlist.songs.length - 1 === nowPlayingIndex) return "";

    let nextPlayingIndex = nowPlayingIndex + 1;
    const nowPlaying = playlist.songs[nextPlayingIndex];
    set({ nowPlayingId: nowPlaying });
    return nowPlaying;
  },
}));
